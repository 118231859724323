import Leaderboard from '@mui/icons-material/Leaderboard';
import { Menu, MenuItemLink, useTranslate } from 'react-admin';
import { CustomResourcesValue } from '../components/admin/AdminResources';
import { useState } from 'react';
import { Collapse, ListItemText, ListItemButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import GradingIcon from '@mui/icons-material/Grading';
import PeopleIcon from '@mui/icons-material/People';
import { useGetTotalUnreadMessageCount } from '../api/hooks/sendbird/useGetTotalUnreadMessageCount';
import { MOONSTAR_THEME } from '../theme';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import { useHasRole } from '../auth/userRoleContext';

export const SideMenuContent = () => {
  const isAdmin = useHasRole('Admin');
  const { data: totalUnreadMessageCount } = useGetTotalUnreadMessageCount();
  const [open, setOpen] = useState(false);
  const translate = useTranslate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const hasUnreadMessages = !!totalUnreadMessageCount;

  return (
    <Menu>
      {isAdmin && (
        <ListItemButton
          onClick={handleToggle}
          sx={{
            height: 36,
            paddingLeft: 2,
            paddingRight: 2,
            borderRadius: 1,
            color: 'text.secondary',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              borderRadius: '0px 100px 100px 0px',
            },
            '&.Mui-selected': { backgroundColor: 'rgba(0, 0, 0, 0.12)' },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Leaderboard sx={{ marginRight: 2, color: 'text.secondary' }} />{' '}
          <ListItemText
            primary={translate('moonstar.analytics.name')}
            sx={{
              paddingLeft: 0.5,
              color: 'text.secondary',
            }}
          />
          {open ? (
            <ExpandLess sx={{ color: 'text.secondary' }} />
          ) : (
            <ExpandMore sx={{ color: 'text.secondary' }} />
          )}
        </ListItemButton>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <MenuItemLink
          to="/dashboard/users"
          primaryText="Users"
          leftIcon={<PeopleIcon />}
          sx={{ paddingLeft: 3 }}
        />
        <MenuItemLink
          to="/dashboard/feed"
          primaryText="Feed"
          leftIcon={<DynamicFeedIcon />}
          sx={{ paddingLeft: 3 }}
        />
        <MenuItemLink
          to="/dashboard/pulseChecks"
          primaryText="Pulse Checks"
          leftIcon={<GradingIcon />}
          sx={{ paddingLeft: 3 }}
        />
      </Collapse>
      <Menu.Item
        to={`/${CustomResourcesValue.CHAT}`}
        primaryText={
          hasUnreadMessages ? `(${totalUnreadMessageCount}) Chat` : 'Chat'
        }
        leftIcon={
          hasUnreadMessages ? (
            <MarkChatUnreadOutlinedIcon
              style={{ color: MOONSTAR_THEME.palette.secondary.main }}
            />
          ) : (
            <ChatBubbleOutlineOutlinedIcon />
          )
        }
      />
      <Menu.ResourceItems />
    </Menu>
  );
};
