import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useNotify, useTranslate } from 'react-admin';

type RegisterAsOperatorParams = {
  id: string;
  channel: GroupChannel;
};

const registerAsOperator = async ({
  id,
  channel,
}: RegisterAsOperatorParams) => {
  return await channel.addOperators([id]);
};

export const useRegisterAsOperator = (
  options?: UseMutationOptions<unknown, Error, RegisterAsOperatorParams>
) => {
  const notify = useNotify();
  const translate = useTranslate();

  return useMutation({
    mutationKey: ['register-as-operator'],
    mutationFn: registerAsOperator,
    ...options,
    onError: () => {
      notify(translate('chat.registerAsOperatorError'), {
        autoHideDuration: 5000,
      });
    },
  });
};
