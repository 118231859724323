import { useI18nextProvider } from 'ra-i18n-i18next';
import { englishTranslations } from './englishTranslations';

// TODO: use resolveBrowserLocale() instead of 'en' once 'ro' is added

export const useMoonstarI18nProvider = () =>
  useI18nextProvider({
    options: {
      resources: {
        en: {
          translation: englishTranslations,
        },
      },
    },
    availableLocales: [
      {
        locale: 'en',
        name: 'English',
      },
    ],
  });
