import {
  FileMessage,
  MultipleFilesMessage,
  UserMessage,
} from '@sendbird/chat/message';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from 'react';
import { ForwardMessageModal } from './GroupCustomChannel/ForwardMessageModal/ForwardMessageModal';
import { GroupChannel } from '@sendbird/chat/groupChannel';

// 10 messages per second per user for our Sendbird plan, might change in the future
// 8 comes from the fact that there's issue with pdf file messages having a different limit
export const FORWARD_MESSAGE_CHANNEL_LIMIT = 8;

export type SendableMessage = UserMessage | FileMessage | MultipleFilesMessage;

export type ForwardMessageContext = {
  message: SendableMessage | null;
  groupChannels: GroupChannel[] | null;
  showModal: boolean;
  setMessage: (message: SendableMessage) => void;
  setGroupChannels: (groupChannels: GroupChannel[]) => void;
  setShowModal: (show: boolean) => void;
};

const ForwardMessageContext = createContext<ForwardMessageContext | undefined>(
  undefined
);

export const ForwardMessageProvider: FC<PropsWithChildren> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState<SendableMessage | null>(null);
  const [groupChannels, setGroupChannels] = useState<GroupChannel[] | null>(
    null
  );

  return (
    <ForwardMessageContext.Provider
      value={{
        message,
        showModal,
        groupChannels,
        setMessage,
        setGroupChannels,
        setShowModal,
      }}
    >
      {props.children}

      <ForwardMessageModal />
    </ForwardMessageContext.Provider>
  );
};

export const useForwardMessageContext = () => {
  const context = useContext(ForwardMessageContext);

  if (context === undefined) {
    throw new Error(
      'useForwardMessageContext must be used within a ForwardMessageProvider'
    );
  }
  return context;
};
