import { ImageField, ImageInput, TextInput } from 'react-admin';
import { SimpleStack } from '../../Moonstar/SimpleStack';
import { MoonstarEditForm } from '../../Moonstar/MoonstarEditForm';
import { MoonstarEdit } from '../../Moonstar/MoonstarEdit';
import { MoonstarEditFormToolbar } from '../../Moonstar/MoonstarEditFormToolbar';
import { useParams } from 'react-router-dom';
import {
  maxFileSize,
  requiredIfFieldsAreBlank,
} from '../../../../utils/validation';

const validation = requiredIfFieldsAreBlank(
  { everyField: ['image', 'content'] },
  'Image or content must be set'
);

const imageValidation = maxFileSize('5MB');

export const NudgeSlideEdit = () => {
  const { nudgeId } = useParams();

  const redirect = () => `nudges/${nudgeId}/slides`;

  return (
    <MoonstarEdit redirect={redirect}>
      <MoonstarEditForm
        toolbar={<MoonstarEditFormToolbar onDeleteRedirect={redirect} />}
      >
        <SimpleStack width="100%">
          <ImageInput
            source="image"
            accept={{ 'image/*': ['.jpg', '.jpeg', '.png', '.gif'] }}
            validate={[validation, imageValidation]}
            sx={{ alignItems: 'start' }}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
          <TextInput
            source="content"
            fullWidth
            multiline
            validate={validation}
          />
        </SimpleStack>
      </MoonstarEditForm>
    </MoonstarEdit>
  );
};
