import { DialogTitle, Step, StepLabel, Stepper } from '@mui/material';

const BulkOperationDialogStepperSteps = [
  {
    id: 'get-template',
    index: 0,
    label: 'Prepare',
  },
  {
    id: 'upload',
    index: 1,
    label: 'Upload',
  },
  {
    id: 'grid',
    index: 2,
    label: 'Overview',
  },
  {
    id: 'process',
    index: 3,
    label: 'Summary',
  },
] as const;

export type BulkOperationDialogSteps =
  (typeof BulkOperationDialogStepperSteps)[number]['id'];

export interface BulkOperationDialogStepperProps {
  step: BulkOperationDialogSteps;
}

export const BulkOperationDialogStepper = ({
  step,
}: BulkOperationDialogStepperProps) => {
  const stepIndex =
    BulkOperationDialogStepperSteps.find((s) => s.id === step)?.index ?? 0;

  return (
    <DialogTitle sx={{ pr: 0 }}>
      <Stepper activeStep={stepIndex} orientation="vertical">
        {Object.entries(BulkOperationDialogStepperSteps).map(([key, value]) => (
          <Step key={key}>
            <StepLabel>{value.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </DialogTitle>
  );
};
