import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../adminDataProvider';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export interface Response {
  posts: {
    total: number;
    lastSevenDays: number;
  };
  comments: {
    total: number;
    lastSevenDays: number;
  };
  reactions: {
    total: number;
    lastSevenDays: number;
  };
}

export interface Params {
  after: string;
  before: string;
}

export const useFeedWidgetsData = (
  params: Params,
  options?: UseQueryOptions<Response, Error, Response>
) => {
  const dataProvider = useDataProvider<AdminDataProvider>();

  return useQuery<Response>({
    queryKey: ['getFeedWidgetsData', params.after, params.before],
    queryFn: () => dataProvider.getFeedWidgetsData(params),
    staleTime: 1000 * 60,
    ...options,
  });
};
