import { Button } from 'react-admin';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
import { CsvRecord } from './types';
import { readCsvExcelFile } from '../../../utils/readCsvExcelFile';

interface BulkOperationDialogUploadProps {
  onNext: (data: CsvRecord[]) => void;
  onBack: () => void;
}

export function BulkOperationDialogUpload({
  onBack,
  onNext,
}: BulkOperationDialogUploadProps) {
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
  const [fileContents, setFileContents] = useState<CsvRecord[]>([]);

  const dropzone = useDropzone({
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.xlsx', '.xls'],
    },
    multiple: true,
    async onDrop(acceptedFiles) {
      setDroppedFiles(acceptedFiles);

      const fileRows = await Promise.all(
        acceptedFiles.map((file) => readCsvExcelFile(file))
      ).then((results) => results.flatMap((result) => result));

      setFileContents(fileRows);
    },
  });

  return (
    <>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box
          {...dropzone.getRootProps()}
          sx={{
            border: '1px dashed grey',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            padding: 4,
          }}
        >
          <input {...dropzone.getInputProps()} />
          {droppedFiles.length > 0 ? (
            <>
              {droppedFiles.map((f) => (
                <DialogContentText variant="h6">{f.name}</DialogContentText>
              ))}
              <DialogContentText
                component="div"
                sx={{ display: 'flex', gap: 0.5 }}
              >
                <DialogContentText component="span">
                  Wrong {droppedFiles.length === 1 ? 'file' : 'files'}?
                </DialogContentText>
                <DialogContentText component="span" variant="button">
                  Upload again
                </DialogContentText>
              </DialogContentText>
            </>
          ) : (
            <>
              <DialogContentText variant="h6">
                Drag your CSV / Excel file here
              </DialogContentText>
              <DialogContentText variant="button">or browse</DialogContentText>
            </>
          )}
        </Box>
        {droppedFiles.length > 0 && (
          <DialogContentText>
            {fileContents.length} {fileContents.length === 1 ? 'row' : 'rows'}{' '}
            detected
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button label="Back" color="secondary" onClick={() => onBack()} />
        <Button
          label="Next"
          color="primary"
          variant="contained"
          disabled={fileContents.length === 0}
          onClick={() => onNext(fileContents)}
        />
      </DialogActions>
    </>
  );
}
