import { saveAs } from '../../../utils/saveAs';
import Papa from 'papaparse';

export type CsvColumns<T extends Record<string, unknown>> =
  (keyof T extends string ? keyof T : never)[];

export interface SaveCsvOptions<T extends Record<string, unknown>> {
  /**
   * Name of the file that will be saved. Should not include the file extension
   */
  basename: string;
  /**
   * Ensures that, even if properties are omitted in the data (such as optional properties), the columns are consistent
   */
  columns?: CsvColumns<T>;
  data: T[];
}

export function saveCsv<T extends Record<string, unknown>>({
  data,
  basename,
  columns,
}: SaveCsvOptions<T>) {
  if (data.length === 0 && !!columns) {
    // Without at least 1 entry, PapaParse will not generate a CSV file. By creating an empty object, we force the columns to be added
    // This only works if the columns are specified (as otherwise, we can't work out what the columns should be if there's no data)
    // https://github.com/mholt/PapaParse/issues/932
    data = [{}] as T[];
  }

  const csv = Papa.unparse(data, {
    columns,
  });

  saveAs(csv, `${basename}.csv`, 'text/csv');
}
