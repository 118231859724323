import React from 'react';
import { TextField, Box } from '@mui/material';

interface DateRangeFilterProps {
  startDate: string;
  endDate: string;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  minDate?: string;
  maxDate?: string;
  onDateChange: () => void;
}

export const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  minDate,
  maxDate = new Date().toISOString().split('T')[0],
  onDateChange,
}) => {
  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      <TextField
        label="Start Date"
        type="date"
        value={startDate}
        onChange={(e) => {
          setStartDate(e.target.value);
          onDateChange();
        }}
        InputLabelProps={{ shrink: true }}
        inputProps={{ min: minDate, max: maxDate }}
        sx={{ width: 150 }}
      />
      <TextField
        label="End Date"
        type="date"
        value={endDate}
        onChange={(e) => {
          setEndDate(e.target.value);
          onDateChange();
        }}
        InputLabelProps={{ shrink: true }}
        inputProps={{ min: minDate, max: maxDate }}
        sx={{ width: 150 }}
      />
    </Box>
  );
};
