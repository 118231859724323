import { HttpClient } from '../httpClient';
import { Response } from '../hooks/useUsersWidgetsData';

export const getUsersWidgetsData = (httpClient: HttpClient) => async () => {
  const response = await httpClient.api('/admin/users/widgets', {
    method: 'GET',
  });

  return response.json as Response;
};
