import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useForwardMessage } from '../../../../../api/hooks/sendbird/useForwardMessage';
import {
  FORWARD_MESSAGE_CHANNEL_LIMIT,
  useForwardMessageContext,
} from '../../ForwardMessageProvider';
import ForwardChannelCard from './ForwardChannelCard';

export const ForwardMessageModal = () => {
  const notify = useNotify();
  const translate = useTranslate();
  const shareMessageContext = useForwardMessageContext();
  const [selectedChannelsUrls, setSelectedChannelsUrls] = useState<string[]>(
    []
  );
  const { message, groupChannels } = shareMessageContext;
  const { mutate: forwardMessage, isPending } = useForwardMessage();

  const handleClose = () => {
    shareMessageContext.setShowModal(false);
  };

  const handleToggle = (url: string) => {
    if (selectedChannelsUrls.includes(url)) {
      setSelectedChannelsUrls(
        selectedChannelsUrls.filter((selectedUrl) => selectedUrl !== url)
      );
    } else {
      setSelectedChannelsUrls([...selectedChannelsUrls, url]);
    }
  };

  const handleShare = () => {
    const groupsToShare = groupChannels?.filter((channel) =>
      selectedChannelsUrls.includes(channel.url)
    );
    if (!groupsToShare?.length || !message) return;

    forwardMessage(
      { message, channels: groupsToShare },
      {
        onSuccess: () => {
          setSelectedChannelsUrls([]);
          handleClose();
          notify(
            <Alert severity="success">
              {translate('chat.messageForwarded')}
            </Alert>,
            {
              autoHideDuration: 5000,
            }
          );
        },
      }
    );
  };

  return (
    <Dialog
      fullWidth
      open={shareMessageContext.showModal}
      onClose={handleClose}
      scroll="paper"
    >
      <DialogTitle>{translate('chat.messageForwardTitle')}</DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        {groupChannels?.map((channel) => {
          if (channel.url === message?.channelUrl) {
            return null;
          }

          return (
            <ForwardChannelCard
              channel={channel}
              selectedChannelsUrls={selectedChannelsUrls}
              isPending={isPending}
              onClick={() => handleToggle(channel.url)}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
        <Button autoFocus onClick={handleClose} disabled={isPending}>
          {translate('chat.cancel')}
        </Button>
        <Box sx={{ m: 1, position: 'relative' }}>
          <Button
            variant="contained"
            onClick={handleShare}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            disabled={!selectedChannelsUrls.length || isPending}
          >
            {translate('chat.forward')}{' '}
            {selectedChannelsUrls.length > 0
              ? `(${selectedChannelsUrls.length}/${FORWARD_MESSAGE_CHANNEL_LIMIT})`
              : ''}
          </Button>
          {isPending && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};
