import { useCallback, useMemo, useRef, useState } from 'react';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';
import useLocalization from '@sendbird/uikit-react/hooks/useLocalization';
import { useGetAllUsers } from '../../../../hooks/useGetAllUsers';
import { useFetchBannedChannelUsers } from '../../../../api/hooks/sendbird/useFetchBannedChannelUsers';
import { UserDto } from '../../../../api/customEndpoints/fetchAllUsers';
import { SearchTextInput } from '../SearchTextInput';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import { UserList } from '../UserList';
import { useTranslate } from 'react-admin';
import {
  GROUP_CHANNEL_MAX_NUMBER_OF_MEMBERS,
  SUPERGROUP_CHANNEL_MAX_NUMBER_OF_MEMBERS,
} from '../GroupCustomChannelList/CreateGroupsModal';
import { ChannelUserInviteCard } from '../ChannelUserInviteCard';
import { useFetchChannelMembers } from '../../../../api/hooks/sendbird/useFetchChannelMembers';
import { useInviteChannelMembers } from '../../../../api/hooks/sendbird/useInviteChannelMembers';
import { LoadingButton } from '../../../LoadingButton';

type UserId = string;
export interface InviteUsersModalProps {
  open: boolean;
  onCancel(): void;
  onSubmit(userIds: UserId[]): void;
}

export function InviteUsersModal({
  onCancel,
  onSubmit,
  open,
}: InviteUsersModalProps) {
  const translate = useTranslate();
  const { channel } = useChannelSettingsContext();
  const { data: growUsers } = useGetAllUsers();
  const { data: bannedUsers } = useFetchBannedChannelUsers();
  const { mutateAsync: inviteUsersAsync, isPending: isInvitingUsers } =
    useInviteChannelMembers();
  const [progress, setProgress] = useState({
    fetched: 0,
    total: channel?.memberCount,
  });
  const { data: channelMembers, isFetching } = useFetchChannelMembers(
    (fetched, total) => {
      setProgress({ fetched, total });
    }
  );
  const [selectedUsers, setSelectedUsers] = useState<UserDto[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const progressPercentage = progress.total
    ? (progress.fetched * 100) / progress.total
    : 0;

  const bannedUserIds = useMemo(() => {
    return new Set(bannedUsers?.map((bu) => bu.userId.toString()));
  }, [bannedUsers]);

  const channelMemberIds = useMemo(() => {
    if (channelMembers === undefined) {
      return new Set<string>();
    }

    return new Set(channelMembers.map((m) => m.userId.toString()));
  }, [channelMembers]);

  // Filter out growUsers who are either banned or members of the channel
  const users = useMemo(() => {
    return (growUsers ?? []).filter(
      (u) =>
        !bannedUserIds.has(String(u.id)) && !channelMemberIds.has(String(u.id))
    );
  }, [growUsers, bannedUserIds, channelMemberIds]);

  const { stringSet } = useLocalization();

  const currentMembersLength = useMemo(() => {
    return channel?.memberCount ?? 0;
  }, [channel?.memberCount]);

  const maxUsers = useMemo(() => {
    const usersLimit = channel?.isSuper
      ? SUPERGROUP_CHANNEL_MAX_NUMBER_OF_MEMBERS
      : GROUP_CHANNEL_MAX_NUMBER_OF_MEMBERS;

    return usersLimit - currentMembersLength;
  }, [channel?.isSuper, currentMembersLength]);

  const handleInviteUsers = useCallback(async () => {
    const userIdsToInvite = selectedUsers.map((u) => `${u.id}`);

    await inviteUsersAsync({ userIdsToInvite, onCancel });
    onSubmit(userIdsToInvite);
    setSelectedUsers([]);
  }, [selectedUsers, inviteUsersAsync, onCancel, onSubmit]);

  const containerRef = useRef(null);

  return (
    <Dialog
      scroll="paper"
      open={open}
      onClose={onCancel}
      onSubmit={() => void handleInviteUsers()}
      fullWidth
    >
      <DialogTitle sx={{ paddingX: 2, paddingY: 1 }}>
        {translate('chat.inviteUsersModalTitle')}
        <SearchTextInput
          label="Search for new members"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }} ref={containerRef}>
        <UserList
          searchTerm={searchTerm}
          userIdsFilter={users.map((u) => u.id)}
          containerRef={containerRef}
          renderUser={(u) => {
            return (
              <ChannelUserInviteCard
                user={u}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                maxUsers={maxUsers}
              />
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        {isFetching && (
          <Typography
            sx={{ marginRight: 'auto' }}
            variant="caption"
            color="primary"
          >
            {translate('chat.loadingMembers', {
              progressPercentage: progressPercentage.toFixed(2),
            })}
          </Typography>
        )}
        <Button variant="outlined" onClick={onCancel}>
          {stringSet.BUTTON__CANCEL}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => void handleInviteUsers()}
          loading={isInvitingUsers}
        >
          {translate('chat.invite', {
            selected: selectedUsers.length + currentMembersLength,
            max: channel?.isSuper
              ? SUPERGROUP_CHANNEL_MAX_NUMBER_OF_MEMBERS
              : GROUP_CHANNEL_MAX_NUMBER_OF_MEMBERS,
          })}
        </LoadingButton>
      </DialogActions>
      {isFetching && (
        <LinearProgress variant="determinate" value={progressPercentage} />
      )}
    </Dialog>
  );
}
