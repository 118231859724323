import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useMutation } from '@tanstack/react-query';
import { flushSync } from 'react-dom';

const TASK_QUEUE_EMPTY_WAIT_FOR_MS = 200;
export interface TaskData<T> {
  data: T;
  id: number;
  status: 'error' | 'idle' | 'pending' | 'success';
}

export type TaskExecutor<TRow extends TaskData<unknown>> = (
  row: TRow
) => Promise<(row: TRow) => TRow>;

export interface BulkTaskOptions<TData, TRow extends TaskData<TData>> {
  setData: Dispatch<SetStateAction<TRow[]>>;
  task: TaskExecutor<TRow> | undefined;
  taskRateLimitDelay: number;
}

/**
 * Executes an async task on each row in the data array. If the data array is updated, the task will be re-executed.
 * @param data The data to process
 * @param setData A callback to invoke whenever the status or result of a task changes
 * @param task The task to invoke on each row
 * @param taskRateLimitDelay The delay between each task execution. This is useful to avoid hitting rate limits
 */
export function useBulkTask<TData, TRow extends TaskData<TData>>({
  setData,
  task,
  taskRateLimitDelay,
}: BulkTaskOptions<TData, TRow>) {
  const lastAsyncValidationRef = useRef(new Date(0));
  const abortControllers = useRef<AbortController[]>([]);

  const { mutate: startTasks } = useMutation({
    mutationFn: async () => {
      if (!task) {
        // No task to run - mark all tasks as completed
        setData((prev) =>
          prev.map((row) => ({
            ...row,
            status: 'success',
          }))
        );

        return;
      }

      const abortController = new AbortController();
      abortControllers.current.push(abortController);

      while (!abortController.signal.aborted) {
        const timeToNextValidation =
          lastAsyncValidationRef.current.getTime() +
          taskRateLimitDelay -
          Date.now();

        if (timeToNextValidation > 0) {
          // Wait for the rate limit delay to pass
          await new Promise((resolve) =>
            setTimeout(resolve, timeToNextValidation)
          );
          continue;
        }

        let rowToProcess: TRow | undefined;
        // We don't want react to delay + optimise this - we want to get the latest row to process immediately
        flushSync(() =>
          setData((prev) => {
            const row = prev.find((r) => r.status === 'idle');
            if (row) {
              rowToProcess = row;
              return prev.map((r) =>
                r.id === row.id
                  ? {
                      ...r,
                      status: 'pending',
                    }
                  : r
              );
            }

            return prev;
          })
        );

        if (!rowToProcess) {
          // Nothing to do, so we'll wait and then re-check in case of edits
          await new Promise((resolve) =>
            setTimeout(resolve, TASK_QUEUE_EMPTY_WAIT_FOR_MS)
          );
          continue;
        }

        lastAsyncValidationRef.current.setTime(Date.now());
        const updateRow = await task(rowToProcess);
        lastAsyncValidationRef.current.setTime(Date.now());

        setData((prev) =>
          prev.map((row) =>
            row.id === rowToProcess!.id ? updateRow(row) : row
          )
        );
      }

      // Clean up the abort controller
      abortControllers.current = abortControllers.current.filter(
        (c) => c !== abortController
      );
    },
  });

  useEffect(() => {
    startTasks();

    return () => {
      abortControllers.current.forEach((c) => c.abort());
    };
  }, [startTasks, task, taskRateLimitDelay]);
}
