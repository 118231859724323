import {
  CircularProgress,
  Container,
  Divider,
  Typography,
} from '@mui/material';
import {
  BadgeImg,
  CenteredTypography,
  PostAvatarRecognition,
  PostContentDiv,
  RecognitionDiv,
} from './styled';
import { postPreviewColors } from './PostPreview';
import { useGetList, useTranslate } from 'react-admin';
import { User } from '../../User/types';
import { useGetCurrentUser } from '../../../../hooks/useGetCurrentUser';
import { PostPreviewContent } from './PostPreviewContent';
import { PostPreviewHeader } from './PostPreviewHeader';
import { PostPreviewFooter } from './PostPreviewFooter';
import { AttachmentsPreview } from './AttachmentsPreview';
import { ReactAdminEditedFile } from '../../../../api/transformHooks/fileUpload';
import { MOONSTAR_THEME } from '../../../../theme';

export type RecognitionPostPreviewData = {
  topicId: number;
  recognizedUserId?: number;
  badgeId?: number;
  content: string;
  attachments?: { src: string }[];
};

type RecognitionPostPreviewProps = {
  topicDisplayName: string;
  previewData: RecognitionPostPreviewData;
};

export const RecognitionPostPreview = ({
  topicDisplayName,
  previewData,
}: RecognitionPostPreviewProps) => {
  const {
    data: currentUser,
    isSuccess: isSuccessCurrentUser,
    isPending,
  } = useGetCurrentUser();
  const {
    data: usersData,
    isSuccess: isSuccessUsers,
    isPending: isPendingUsers,
  } = useGetList('users', { filter: { id: previewData.recognizedUserId } });
  const { data: badgesData, isSuccess: isSuccessBadges } = useGetList('badges');
  const translate = useTranslate();

  const recognizedUser: User | undefined =
    isSuccessUsers &&
    usersData.find((u) => u.id === previewData.recognizedUserId);

  const recognizedUserDisplayName =
    recognizedUser && `${recognizedUser.firstName} ${recognizedUser.lastName}`;

  const currentUserDisplayName =
    isSuccessCurrentUser && `${currentUser.firstName} ${currentUser.lastName}`;

  const getBadgeName = (): string => {
    if (previewData.badgeId && isSuccessBadges) {
      return badgesData.find((b) => b.id === previewData.badgeId)?.name;
    } else return '';
  };

  const getBadgeUrl = (): string => {
    if (previewData.badgeId && isSuccessBadges) {
      return badgesData.find((b) => b.id === previewData.badgeId)?.imageUrl;
    } else return '';
  };

  if (isPending || isPendingUsers) {
    return (
      <CircularProgress
        color="primary"
        sx={{ alignSelf: 'center', backgroundColor: 'transparent' }}
      />
    );
  }

  return (
    <>
      <PostPreviewHeader
        currentUserDisplayName={
          currentUserDisplayName ? currentUserDisplayName : 'You'
        }
        currentUserPictureUrl={currentUser?.pictureUrl ?? ''}
        topicDisplayName={topicDisplayName}
      />

      <>
        <Typography
          sx={{ textAlign: 'center' }}
          color={postPreviewColors.secondaryText}
          component="span"
        >
          {currentUserDisplayName || 'You'}&nbsp;
          <Typography component="span">
            {translate('moonstar.post.preview.recognition.hasRecognized')}
          </Typography>
          <Typography
            color={postPreviewColors.recognitionValue}
            component="span"
          >
            &nbsp;{recognizedUserDisplayName}
          </Typography>
          <Divider
            sx={{
              marginTop: 1,
              backgroundColor: MOONSTAR_THEME.palette.primary.main,
              height: 1.5,
            }}
          />
        </Typography>
      </>

      {/* Recognition content*/}
      {previewData.badgeId && isSuccessBadges && (
        <>
          <PostAvatarRecognition src={recognizedUser?.pictureUrl} />
          <RecognitionDiv>
            {/* Centered on a single line */}
            <CenteredTypography>
              <Typography variant="subtitle1" fontWeight="bold">
                {recognizedUserDisplayName}
              </Typography>
              <Typography component="span">
                {translate('moonstar.post.preview.recognition.hasEarned')}
                <Typography
                  component="span"
                  color={postPreviewColors.recognitionValue}
                >
                  {' '}
                  {getBadgeName()}
                </Typography>
                <Typography component="span">
                  {' '}
                  {translate('moonstar.post.preview.recognition.badge')}
                </Typography>
              </Typography>
            </CenteredTypography>
          </RecognitionDiv>
        </>
      )}

      {/* Post content */}
      <PostContentDiv>
        {previewData.content && (
          <Container
            maxWidth="sm"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              borderRadius: 8,
              backgroundColor: postPreviewColors.recognitionContentContainer,
              padding: 8,
              gap: 12,
            }}
          >
            <BadgeImg src={getBadgeUrl()} alt="badgeImage" />
            <div style={{ flex: 1, overflow: 'hidden' }}>
              <PostPreviewContent content={previewData.content} />
            </div>
          </Container>
        )}
      </PostContentDiv>

      {/* Attachments */}
      {previewData.attachments && (
        <AttachmentsPreview
          attachments={previewData.attachments as ReactAdminEditedFile[]}
        />
      )}

      {/* Reactions and comments */}
      <PostPreviewFooter />
    </>
  );
};
