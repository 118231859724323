import { Button, useTranslate } from 'react-admin';
import {
  Alert,
  AlertTitle,
  Box,
  DialogActions,
  DialogContent,
  LinearProgress,
  Typography,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { CsvRecord, ProcessRow, RowProcessor } from './types';
import { useBulkTask } from './useBulkTask';
import { saveCsv } from './saveCsv';
import { ZodCsvSchema } from './bulkZodUtils';

export interface BulkOperationDialogProcessProps<T extends CsvRecord> {
  label: string;
  schema?: ZodCsvSchema<T>;
  data: T[];
  processRow: RowProcessor<T>;
  processingRateLimitDelay: number;
  onNext: () => void;
}

export function BulkOperationDialogProcess<T extends CsvRecord>({
  label,
  data,
  processRow,
  processingRateLimitDelay,
  onNext,
}: BulkOperationDialogProcessProps<T>) {
  const translate = useTranslate();
  const [startTime] = useState(() => new Date());

  const [processingState, setProcessingState] = useState(() =>
    data.map<ProcessRow<T>>((r, idx) => ({
      status: 'idle',
      data: r,
      errors: [],
      id: idx,
    }))
  );

  useBulkTask({
    setData: setProcessingState,
    task: processRow,
    taskRateLimitDelay: processingRateLimitDelay,
  });

  const progress = useMemo(() => {
    const total = processingState.length;
    let success = 0,
      pending = 0,
      idle = 0,
      error = 0;

    for (const row of processingState) {
      switch (row.status) {
        case 'success':
          success++;
          break;
        case 'error':
          error++;
          break;
        case 'pending':
          pending++;
          break;
        case 'idle':
          idle++;
      }
    }

    return {
      total,
      notDone: pending + idle,
      success,
      error,
      percentageDone: ((success + error) / total) * 100,
      percentageNotIdle: ((success + error + pending) / total) * 100,
    };
  }, [processingState]);

  const estimatedTimeRemaining = useMemo(() => {
    if (progress.notDone === 0) {
      return '';
    } else if (progress.notDone === progress.total) {
      return 'Calculating time remaining...';
    }

    const elapsedTime = new Date().getTime() - startTime.getTime();
    const estimatedTotalTime =
      (elapsedTime / (progress.total - progress.notDone)) * progress.total;

    const timeRemaining = estimatedTotalTime - elapsedTime;

    if (timeRemaining < 0) {
      return 'Unknown time remaining';
    } else if (timeRemaining < 1_000) {
      return 'Less than 1 second remaining';
    } else if (timeRemaining < 15_000) {
      return 'A few seconds remaining';
    } else if (timeRemaining < 90_000) {
      return 'About a minute remaining';
    } else if (timeRemaining < 3_600_000) {
      return `About ${Math.floor(timeRemaining / 60_000)} minutes remaining`;
    } else {
      return `About ${Math.floor(timeRemaining / 3_600_000)} hours remaining`;
    }
  }, [startTime, progress]);

  const downloadErrors = useCallback(() => {
    saveCsv({
      data: processingState
        .filter((r) => r.status === 'error')
        .map((r) => ({
          ...r.data,
          errorMessage: r.errors.join(', '),
        })),
      basename: `${translate(label)} - Failed`,
    });
  }, [label, processingState, translate]);

  return (
    <>
      <DialogContent>
        {progress.notDone > 0 ? (
          <>
            <Box sx={{ flexGrow: 1, mr: 1 }}>
              <LinearProgress
                variant="buffer"
                value={progress.percentageDone}
                valueBuffer={progress.percentageNotIdle}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">
                {progress.percentageDone.toFixed(0)}%
              </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary">
              {estimatedTimeRemaining}
            </Typography>
          </>
        ) : progress.success === progress.total ? (
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {progress.success}/{progress.total} records were processed
            successfully. You can now close this dialog.
          </Alert>
        ) : progress.success > 0 ? (
          <Alert severity="warning">
            <AlertTitle>Partial success</AlertTitle>
            {progress.success}/{progress.total} records were processed
            successfully, but {progress.error} records failed to process. Please
            export the failed records, check the reasons within, and try again.
          </Alert>
        ) : (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            All records failed to process. Please export the failed records,
            check the reasons within, and try again.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {progress.notDone === 0 && progress.error > 0 && (
          <Button
            label="Export failed"
            color="error"
            variant="contained"
            onClick={() => downloadErrors()}
          />
        )}
        <Button
          label="Done"
          color="primary"
          variant="contained"
          disabled={progress.notDone > 0}
          onClick={() => onNext()}
        />
      </DialogActions>
    </>
  );
}
