import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';

type WidgetProps = {
  title: string;
  textPrimary: string | number;
  textSecondary?: string;
  description: string;
  color: string;
};

export const Widget = ({
  title,
  textPrimary: content,
  textSecondary: subcontent,
  description,
  color,
}: WidgetProps) => {
  return (
    <Box
      sx={{
        padding: 1.5,
        borderRadius: 2,
        backgroundColor: 'background.paper',
        boxShadow: 3,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="subtitle2">{title}</Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'baseline',
          gap: 1,
        }}
      >
        <Typography variant="h5" color={color} sx={{ fontWeight: 'bold' }}>
          {content}
        </Typography>
        {subcontent && (
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              fontSize: '0.8rem',
            }}
          >
            {subcontent}
          </Typography>
        )}
      </Box>

      <Typography
        variant="caption"
        sx={{
          display: 'block',
          marginTop: 0.5,
          color: 'grey.600',
          fontSize: '0.6rem',
          lineHeight: 1.2,
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};
