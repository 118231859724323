import { MutableRefObject, ReactElement } from 'react';
import type {
  GroupChannelCreateParams,
  Member,
} from '@sendbird/chat/groupChannel';
import type { User } from '@sendbird/chat';
import { useSendbirdStateContext } from '@sendbird/uikit-react';
import { useSendbirdContext } from './SendbirdProviderWithSDK';
import { useLocalization } from '@sendbird/uikit-react/hooks/useLocalization';
import Label, {
  LabelColors,
  LabelTypography,
} from '@sendbird/uikit-react/ui/Label';
import Avatar from '@sendbird/uikit-react/ui/Avatar';
import Button, { ButtonTypes } from '@sendbird/uikit-react/ui/Button';
import { MenuItems } from '@sendbird/uikit-react/ui/ContextMenu';

// Based on:
// - https://github.com/sendbird/sendbird-uikit-react/blob/main/src/ui/UserProfile/index.tsx
// - https://github.com/sendbird/sendbird-uikit-react/blob/main/src/ui/MessageContent/MessageProfile/index.tsx
// ^ Customization is to make 1-to-1 channels have `isDistinct: true`
// ^ Also uses `RenderUserProfileProps` instead of the custom UserProfile-specific Props that Sendbird uses

interface Props {
  user: User | Member;
  currentUserId: string;
  avatarRef: MutableRefObject<any>;
  close(): void;
}

function UserProfile({
  close,
  user,
  currentUserId,
  avatarRef,
}: Props): ReactElement {
  const state = useSendbirdStateContext();
  const { sb } = useSendbirdContext();
  const { stringSet } = useLocalization();
  const currentUserId_ = currentUserId || state.config.userId;

  return (
    <MenuItems
      closeDropdown={close}
      parentContainRef={avatarRef}
      parentRef={avatarRef}
      style={{ paddingTop: '0px', paddingBottom: '0px' }}
    >
      <div className="sendbird__user-profile">
        <section className="sendbird__user-profile-avatar">
          <Avatar height="80px" width="80px" src={user.profileUrl} />
        </section>
        <section className="sendbird__user-profile-name">
          <Label type={LabelTypography.H_2} color={LabelColors.ONBACKGROUND_1}>
            {user.nickname || stringSet.NO_NAME}
          </Label>
        </section>
        {user.userId !== currentUserId_ && (
          <section className="sendbird__user-profile-message">
            <Button
              type={ButtonTypes.SECONDARY}
              onClick={() => {
                // Create 1:1 channel
                const params: GroupChannelCreateParams = {
                  isDistinct: true,
                  invitedUserIds: user.userId ? [user.userId] : [],
                  operatorUserIds: [currentUserId_],
                };
                sb?.groupChannel
                  .createChannel(params)
                  .then((groupChannel) => {
                    state.config.onStartDirectMessage?.(groupChannel);
                  })
                  .catch((err: unknown) => console.error(err));
              }}
            >
              {stringSet.USER_PROFILE__MESSAGE}
            </Button>
          </section>
        )}
        <div className="sendbird__user-profile-separator" />
        <section className="sendbird__user-profile-userId">
          <Label
            className="sendbird__user-profile-userId--label"
            type={LabelTypography.CAPTION_2}
            color={LabelColors.ONBACKGROUND_2}
          >
            {stringSet.USER_PROFILE__USER_ID}
          </Label>
          <Label
            className="sendbird__user-profile-userId--value"
            type={LabelTypography.BODY_1}
            color={LabelColors.ONBACKGROUND_1}
          >
            {user.userId}
          </Label>
        </section>
      </div>
    </MenuItems>
  );
}

export default UserProfile;
