import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useNotify, useTranslate } from 'react-admin';

type UnregisterAsOperatorParams = {
  id: string;
  channel: GroupChannel;
};

const unregisterAsOperator = async ({
  id,
  channel,
}: UnregisterAsOperatorParams) => {
  return await channel.removeOperators([id]);
};

export const useUnregisterAsOperator = (
  options?: UseMutationOptions<unknown, Error, UnregisterAsOperatorParams>
) => {
  const notify = useNotify();
  const translate = useTranslate();

  return useMutation({
    mutationKey: ['unregister-as-operator'],
    mutationFn: unregisterAsOperator,
    ...options,
    onError: () => {
      notify(translate('chat.unregisterAsOperatorError'), {
        autoHideDuration: 5000,
      });
    },
  });
};
