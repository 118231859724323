import { Button, useTranslate } from 'react-admin';
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import DataGrid, { Column } from 'react-data-grid';
import { BulkOperationDialogGridEditCell } from './BulkOperationDialogGridEditCell';
import { BulkOperationDialogGridCell } from './BulkOperationDialogGridCell';
import { CsvRecord, GridRow, GridRowValidator } from './types';
import { validateCsvSync } from './bulkValidation';
import { BulkOperationDialogGridFooter } from './BulkOperationDialogGridFooter';
import { useBulkTask } from './useBulkTask';
import { CsvColumns, saveCsv } from './saveCsv';
import { ZodCsvSchema, getZodCsvRowObjectSchema } from './bulkZodUtils';

interface BulkOperationDialogGridProps<T extends CsvRecord = CsvRecord> {
  onNext: (records: T[]) => void;
  onBack: () => void;
  label: string;
  validateRow?: GridRowValidator<T>;
  schema: ZodCsvSchema<T>;
  data: T[];
  validationRateLimitDelay: number;
}

export function BulkOperationDialogGrid<T extends CsvRecord>({
  data,
  label,
  schema,
  validateRow,
  validationRateLimitDelay,
  onBack,
  onNext,
}: BulkOperationDialogGridProps<T>) {
  const translate = useTranslate();
  const [gridData, setGridData] = useState(() =>
    validateCsvSync([], data, schema)
  );

  const handleRowsChange = useCallback(
    (rows: GridRow<T>[]) =>
      setGridData((oldData) =>
        validateCsvSync(
          oldData,
          rows.map((r) => r.data),
          schema
        )
      ),
    [schema]
  );

  useBulkTask<T, GridRow<T>>({
    setData: setGridData,
    task: validateRow,
    taskRateLimitDelay: validationRateLimitDelay,
  });

  const columns = useMemo<Column<GridRow<T>>[]>(() => {
    const allKeys = Object.keys(
      getZodCsvRowObjectSchema(schema).shape
    ) as (keyof T & string)[];

    const cols = [
      {
        key: 'rowNumber',
        name: '',
        width: 40,
        renderCell: (props) => (
          <Box display="flex" alignItems="center" gap={1}>
            {props.rowIdx + 1 /* rowIdx is 0-based, so add 1 for display */}
            {props.row.status === 'idle' || props.row.status === 'pending' ? (
              <CircularProgress size={16} />
            ) : null}
          </Box>
        ),
      },
      ...allKeys.map<Column<GridRow<T>>>((k) => ({
        key: k,
        name: k,
        renderEditCell: (props) => (
          <BulkOperationDialogGridEditCell {...props} />
        ),
        renderCell: (props) => <BulkOperationDialogGridCell {...props} />,
      })),
    ] satisfies Column<GridRow<T>>[];

    return cols;
  }, [schema]);

  const canUpload = useMemo(
    () => gridData.every((row) => row.status === 'success'),
    [gridData]
  );

  const exportEdits = useCallback(() => {
    const csvData = gridData.map((row) => row.data);
    saveCsv<T>({
      data: csvData,
      basename: `${translate(label)} - Edits`,
      columns: Object.keys(
        getZodCsvRowObjectSchema(schema).shape
      ) as CsvColumns<T>,
    });
  }, [gridData, label, schema, translate]);

  return (
    <>
      <DialogContent>
        <DataGrid
          columns={columns}
          rows={gridData}
          rowKeyGetter={(row) => row.id}
          onRowsChange={handleRowsChange}
          defaultColumnOptions={{ resizable: true }}
          rowClass={(row) => {
            switch (row.status) {
              case 'error':
                return 'row-error';
              default:
                return undefined;
            }
          }}
        />

        <BulkOperationDialogGridFooter gridData={gridData} />
      </DialogContent>
      <DialogActions>
        <Button label="Back" color="secondary" onClick={onBack} />
        <Button label="Export edits" color="secondary" onClick={exportEdits} />
        <Button
          label="Start processing"
          color="primary"
          variant="contained"
          disabled={!canUpload}
          onClick={() => onNext(gridData.map((r) => r.data))}
        />
      </DialogActions>
    </>
  );
}
