import {
  DatagridConfigurable,
  DateField,
  FunctionField,
  ReferenceField,
  TextField,
  useTranslate,
} from 'react-admin';
import { ResourcesValue } from '../AdminResources';
import { MoonstarList } from '../Moonstar/MoonstarList';
import { PostFilters } from './PostFilters';
import { PostToolbarActions } from './PostToolbarActions';
import { truncatePost } from './truncatePost';
import { saveCsv } from '../BulkOperation/saveCsv';

const exporter = (posts: any) => {
  const mapped = posts.map((p: any) => {
    return {
      Id: p.id,
      Topic: p.topic,
      CreatedOn: p.createdOn,
      AuthorId: p.createdById,
      AuthorInternalID: p.author.internalId,
      Author: p.author.name,
      Department: p.author.department,
      JobTitle: p.author.jobTitle,
      Region: p.author.region,
      Units: p.author.units,
      Title: p.title,
      Content: p.content,
      Views: p.numberOfViews,
      Comments: p.numberOfComments,
      Likes: p.numberOfReactions,
      Badge: p.badge,
      AwardedUser: p.awardedUser,
      AwardedUserId: p.awardedUserId,
      AwardedUserInternalID: p.awardedUserInternalId,
    };
  });

  saveCsv({
    data: mapped,
    basename: ResourcesValue.POSTS,
  });
};

export const PostList = () => {
  const translate = useTranslate();
  const postContentLabel = translate('resources.posts.fields.postContent');

  return (
    <MoonstarList
      sort={{ field: 'createdOn', order: 'DESC' }}
      actions={<PostToolbarActions />}
      filters={PostFilters}
      exporter={exporter}
    >
      <DatagridConfigurable
        bulkActionButtons={false}
        omit={['badge', 'awardedUserId']}
      >
        <TextField source="id" />
        <DateField
          source="createdOn"
          showTime={true}
          options={{
            dateStyle: 'medium',
            timeStyle: 'short',
          }}
        />
        <ReferenceField source="createdById" reference="users">
          <FunctionField
            render={(record: { firstName: any; lastName: any }) =>
              `${record.firstName} ${record.lastName} `
            }
          />
        </ReferenceField>
        <ReferenceField source="topicId" reference="topics" link={false}>
          <TextField source="displayName" />
        </ReferenceField>
        <FunctionField
          label={postContentLabel}
          render={(record: { title?: string; content?: string }) =>
            truncatePost(record)
          }
        />
        <TextField source="numberOfViews" sortable={false} />
        <TextField source="numberOfComments" sortable={false} />
        <TextField source="numberOfReactions" sortable={false} />
        <TextField source="badge" sortable={false} />
        <ReferenceField source="awardedUserId" reference="users">
          <FunctionField
            render={(record: { firstName: any; lastName: any }) =>
              `${record.firstName} ${record.lastName} `
            }
          />
        </ReferenceField>
      </DatagridConfigurable>
    </MoonstarList>
  );
};
