import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';
import { useQuery } from '@tanstack/react-query';
import { User } from '@sendbird/chat';

const fetchMutedUsers = async (
  channel: GroupChannel | null,
  onProgress?: (fetched: number, total: number) => void
) => {
  if (!channel) return [];

  const mutedUserListQuery = channel.createMutedUserListQuery({
    limit: 100,
  });

  let allMutedUsers: User[] = [];
  while (mutedUserListQuery.hasNext) {
    const mutedUsers = await mutedUserListQuery.next();
    allMutedUsers = [...allMutedUsers, ...mutedUsers];

    if (onProgress) {
      onProgress(allMutedUsers.length, channel.memberCount);
    }
  }

  return allMutedUsers;
};

export const useFetchMutedUsers = (
  onProgress?: (fetched: number, total: number) => void
) => {
  const { channel } = useChannelSettingsContext();

  return useQuery<User[]>({
    queryKey: ['muted-users', channel],
    queryFn: () => fetchMutedUsers(channel, onProgress),
    staleTime: 60 * 1000, // 1 minute
  });
};
