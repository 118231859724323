import React, { useMemo } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  Box,
  Avatar,
  Typography,
  Checkbox,
} from '@mui/material';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { getChatChannelName } from '../../../../../utils/getChatChannelName';
import { useGetCurrentUser } from '../../../../../hooks/useGetCurrentUser';
import { FORWARD_MESSAGE_CHANNEL_LIMIT } from '../../ForwardMessageProvider';

type ForwardChannelCardProps = {
  channel: GroupChannel;
  selectedChannelsUrls: string[];
  isPending: boolean;
  onClick: () => void;
  disabled?: boolean;
};

const ForwardChannelCard: React.FC<ForwardChannelCardProps> = ({
  channel,
  selectedChannelsUrls,
  isPending,
  onClick,
  disabled,
}) => {
  const { data: currentUser } = useGetCurrentUser();
  const isSelected = useMemo(
    () => selectedChannelsUrls.includes(channel.url),
    [channel, selectedChannelsUrls]
  );
  const shouldDisableCard =
    selectedChannelsUrls.length >= FORWARD_MESSAGE_CHANNEL_LIMIT && !isSelected;

  return (
    <Card key={channel.url} square variant="outlined" elevation={0}>
      <CardActionArea
        onClick={onClick}
        disabled={isPending || shouldDisableCard || disabled}
      >
        <CardContent
          sx={{
            display: 'flex',
            height: 48,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
              gap: 2,
              maxWidth: '100%',
            }}
          >
            <Avatar alt={`Channel: ${channel.name}`} src={channel.coverUrl} />

            <Typography
              variant="subtitle1"
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {currentUser && getChatChannelName(channel, currentUser)}{' '}
            </Typography>

            <Checkbox
              edge="end"
              checked={isSelected}
              tabIndex={-1}
              disableRipple
              style={{ marginLeft: 'auto' }}
              disabled={shouldDisableCard}
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ForwardChannelCard;
