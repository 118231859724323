import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../adminDataProvider';
import { HttpClient } from '../httpClient';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export const getPulseQuestionsChartData =
  (httpClient: HttpClient) => async () => {
    const response = await httpClient.api('/admin/pulse/chart', {
      method: 'GET',
    });

    return response.json as Entry[];
  };

export interface Entry {
  id: number;
  text: string;
  total: number;
  answers: {
    type: number;
    count: number;
  }[];
}

export interface Params {
  after: string;
  before: string;
}

export const usePulseQuestionsChartData = (
  params: Params,
  options?: UseQueryOptions<Entry[], Error, Entry[]>
) => {
  const dataProvider = useDataProvider<AdminDataProvider>();

  return useQuery<Entry[]>({
    queryKey: ['getPulseQuestionsChartData', params.after, params.before],
    queryFn: () => dataProvider.getPulseQuestionsChartData(params),
    staleTime: 1000 * 60,
    ...options,
  });
};
