import { useState, ChangeEvent, FC, useCallback, useRef } from 'react';
import { Button, useDataProvider, useNotify, useTranslate } from 'react-admin';
import DataGrid, { Column, RenderEditCellProps } from 'react-data-grid';
import {
  Alert,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { AdminDataProvider } from '../../../api/adminDataProvider';
import { useMutation } from '@tanstack/react-query';
import Papa from 'papaparse';
import {
  DisableUserResultRow,
  DisableUserRow,
  UserCsvValidationError,
  createDisableUserSchema,
} from './types';
import 'react-data-grid/lib/styles.css';
import './ImportUsersButton.css';
import * as XLSX from 'xlsx';
import { saveCsv } from '../BulkOperation/saveCsv';

async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const BulkDisableUsersButton: FC = () => {
  const translate = useTranslate();
  const label = translate('moonstar.bulk_disable.tooltip');
  const bulkDisableRef = useRef<HTMLInputElement>(null);
  const dataProvider = useDataProvider<AdminDataProvider>();
  const notify = useNotify();

  const [csvData, setCsvData] = useState<DisableUserResultRow[]>([]);
  const [validationErrors, setValidationErrors] = useState<
    UserCsvValidationError[][]
  >([]);
  const [open, setOpen] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState({
    successCount: 0,
    errorCount: 0,
    total: 0,
    unprocessed: [] as DisableUserResultRow[],
  });

  const handleCellEdit = async (
    rowIdx: number,
    columnKey: keyof DisableUserRow,
    newValue: string
  ) => {
    const updatedRow = {
      ...csvData[rowIdx],
      [columnKey]: newValue,
    } as DisableUserResultRow;

    const rows = [...csvData];
    rows[rowIdx] = updatedRow;
    setCsvData(rows);

    const rowErrors = await validateRow(updatedRow);

    const newValidationErrors = { ...validationErrors };
    if (rowErrors.length > 0) {
      newValidationErrors[rowIdx] = rowErrors;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete newValidationErrors[rowIdx];
    }

    setValidationErrors(newValidationErrors);
  };

  const autoFocusAndSelect = (input: HTMLInputElement | null) => {
    input?.focus();
    input?.select();
  };

  const TextEditor = <TRow, TSummaryRow>({
    row,
    column,
    onRowChange,
    onClose,
    rowIdx,
  }: RenderEditCellProps<TRow, TSummaryRow> & { rowIdx: number }) => {
    const [localValue, setLocalValue] = useState<string>(
      row[column.key as keyof TRow] as unknown as string
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setLocalValue(event.target.value);
    };

    const handleBlur = async () => {
      onRowChange({ ...row, [column.key]: localValue });
      await handleCellEdit(
        rowIdx,
        column.key as keyof DisableUserRow,
        localValue
      );
      onClose(true, false);
    };

    const handleKeyDown = async (
      event: React.KeyboardEvent<HTMLInputElement>
    ) => {
      if (event.key === 'Enter') {
        onRowChange({ ...row, [column.key]: localValue });
        await handleCellEdit(
          rowIdx,
          column.key as keyof DisableUserRow,
          localValue
        );
        onClose(true, false);
      } else if (event.key === 'Escape') {
        onClose(false, false);
      }
    };

    return (
      <input
        className="textEditor rdg-cell"
        ref={autoFocusAndSelect}
        value={localValue}
        onChange={handleChange}
        onBlur={() => void handleBlur()}
        onKeyDown={(e) => void handleKeyDown(e)}
      />
    );
  };

  const columns: readonly Column<DisableUserResultRow>[] = [
    {
      key: 'rowNumber',
      name: '',
      width: 40,
      renderCell: (props) => <div>{props.rowIdx + 1}</div>, // rowIdx is 0-based, so add 1 for display
    },
    {
      key: 'id',
      name: 'ID',
      renderEditCell: TextEditor,
      renderCell: ({ row, rowIdx }) => renderCellWithTooltip(row, 'id', rowIdx),
    },
    {
      key: 'internalId',
      name: 'Internal ID',
      renderEditCell: TextEditor,
      renderCell: ({ row, rowIdx }) =>
        renderCellWithTooltip(row, 'internalId', rowIdx),
    },
    {
      key: 'firstName',
      name: 'First name',
      editable: false,
    },
    {
      key: 'lastName',
      name: 'Last name',
      editable: false,
    },
  ];

  const onClickBulkDisable = useCallback(() => {
    if (!bulkDisableRef.current) return;
    bulkDisableRef.current.value = '';
    bulkDisableRef.current.click();
  }, []);

  const validateRow = async (user: DisableUserRow) => {
    const schema = createDisableUserSchema(dataProvider);
    const validationResult = await schema.safeParseAsync(user);

    const errors =
      validationResult.error?.errors.map((error) => ({
        property: error.path.toString(),
        message: error.message,
      })) ?? [];

    if (!errors.length && validationResult.data) {
      user.id = validationResult.data.id ?? '';
      user.internalId = validationResult.data.internalId ?? '';
      user.firstName = validationResult.data.firstName ?? '';
      user.lastName = validationResult.data.lastName ?? '';
    } else {
      user.firstName = '';
      user.lastName = '';
    }

    return errors;
  };

  const validateAllRows = async (jsonData: DisableUserRow[]) => {
    const validationPromises = jsonData.map(async (user, index) => {
      const validationErrors = await validateRow(user);

      return validationErrors.length > 0
        ? { index, errors: validationErrors }
        : null;
    });

    const validationResults = await Promise.all(validationPromises);

    const errors: UserCsvValidationError[][] = [];
    validationResults.forEach((result) => {
      if (result) {
        errors[result.index] = result.errors;
      }
    });

    return errors;
  };

  const parseCsv = (file: File): Promise<DisableUserResultRow[]> => {
    return new Promise((resolve, reject) => {
      Papa.parse<DisableUserRow>(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => header.trim(),
        transform: (value) => value.trim(),
        complete: (results) => {
          const filteredData = results.data.filter((row) =>
            Object.values(row).some(
              (value: string | null) => value !== '' && value !== null
            )
          );
          resolve(
            filteredData.map((row) => ({
              ...row,
              status: 'pending',
            }))
          );
        },
        error: (error) => reject(error),
      });
    });
  };

  const parseXlsx = async (file: File) => {
    const buffer = await file.arrayBuffer();
    const workbook = XLSX.read(buffer, { type: 'array' });
    const firstSheet = workbook.Sheets[workbook.SheetNames[0]!]!;

    const jsonData: any[][] = XLSX.utils.sheet_to_json(firstSheet, {
      header: 1,
      defval: '',
      blankrows: false,
    });

    const parsedData: DisableUserResultRow[] = jsonData.slice(1).map(
      (row) =>
        ({
          id: row[0] ? String(row[0]) : undefined,
          internalId: row[1] ? String(row[1]) : undefined,
          firstName: '',
          lastName: '',
          status: 'pending',
        }) as DisableUserResultRow
    );
    return parsedData;
  };

  const bulkDisableUsers = useMutation({
    mutationFn: async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) return;

      setCsvData([]);
      setValidationErrors([]);
      setShowSummary(false);
      setProcessing(false);
      setProgress({
        successCount: 0,
        errorCount: 0,
        total: 0,
        unprocessed: [],
      });

      let rows: DisableUserResultRow[] = [];

      if (file.name.endsWith('.csv') || file.name.endsWith('.tsv')) {
        try {
          rows = await parseCsv(file);
        } catch (error) {
          console.error('Error processing CSV file:', error);
          throw error;
        }
      } else if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
        try {
          rows = await parseXlsx(file);
        } catch (error) {
          console.error('Error processing XLSX file:', error);
          throw error;
        }
      }

      const errors = await validateAllRows(rows);
      setValidationErrors(errors);
      setCsvData(rows);
      setOpen(true);
    },
    onError: () => {
      notify(
        <Alert severity="error">
          {translate('moonstar.bulk_disable.error')}
        </Alert>,
        {
          autoHideDuration: 5000,
        }
      );
    },
  });

  const handleUpload = async () => {
    setProcessing(true);
    setShowSummary(true);
    let successCount = 0;
    let errorCount = 0;

    const rows = csvData;
    const unprocessedRows = progress.unprocessed;

    for (let i = 0; i < rows.length; i++) {
      rows[i]!.status = 'processing';
      setCsvData([...rows]);
      try {
        const response = await dataProvider.setUserEnabled(rows[i]!.id, false);
        if (response.status == 200 || response.status == 204) {
          successCount++;
          rows[i]!.status = 'success';
        } else {
          errorCount++;
          rows[i]!.status = 'error';
          unprocessedRows.push({
            ...rows[i],
            errorMessage: response.json,
          } as DisableUserResultRow);
        }
      } catch (error) {
        console.error(error, rows[i]);
        errorCount++;
        rows[i]!.status = 'error';
        unprocessedRows.push({
          ...rows[i],
          errorMessage:
            'Unexpected error. Please reach out to Moonstar Support.',
        } as DisableUserResultRow);
      }
      setCsvData([...rows]);
      setProgress({
        successCount,
        errorCount,
        total: csvData.length,
        unprocessed: unprocessedRows,
      });
      // due too clerk's 20requests/10s limit
      await sleep(500);
    }

    setProcessing(false);
    if (errorCount === 0) {
      notify(
        <Alert severity="success">
          {translate('moonstar.bulk_disable.success')}
        </Alert>
      );
    } else {
      notify(
        <Alert severity="error">
          {translate('moonstar.bulk_disable.partial_success', {
            total_failed: errorCount,
          })}
        </Alert>
      );
    }
  };

  const handleExport = () => {
    saveCsv<Record<keyof DisableUserResultRow, string>>({
      data: progress.unprocessed,
      basename: 'bulk-disable-errors',
    });
  };

  const renderCellWithTooltip = (
    row: DisableUserRow,
    columnKey: keyof DisableUserRow,
    rowIdx: number
  ) => {
    const errors = validationErrors[rowIdx] as
      | UserCsvValidationError[]
      | undefined;
    const errorMessage = errors?.find((error) => error.property === columnKey)
      ?.message;

    return errorMessage ? (
      <Tooltip title={errorMessage} placement="top" arrow>
        <div className="rdg-cell-error">{row[columnKey] || ''}</div>
      </Tooltip>
    ) : (
      <div>{row[columnKey] || ''}</div>
    );
  };

  return (
    <>
      <Tooltip title={label}>
        <div>
          <Button
            label="moonstar.bulk_disable.label"
            onClick={onClickBulkDisable}
            disabled={bulkDisableUsers.isPending}
          >
            <RemoveDoneIcon style={{ fontSize: '20' }} />
          </Button>
          <input
            ref={bulkDisableRef}
            type="file"
            style={{ display: 'none' }}
            onChange={bulkDisableUsers.mutate}
            accept=".csv,.tsv,.xlsx,.xls"
          />
        </div>
      </Tooltip>

      <Dialog
        open={open}
        onClose={!processing ? () => setOpen(false) : undefined}
        maxWidth="xl"
        fullWidth
      >
        <DialogTitle>{translate('moonstar.bulk_disable.label')}</DialogTitle>
        <DialogContent>
          <div className="onboarding-grid-wrapper">
            <DataGrid
              columns={columns}
              rows={csvData}
              onRowsChange={setCsvData}
              defaultColumnOptions={{ resizable: true }}
              rowClass={(row) => {
                switch (row.status) {
                  case 'processing':
                    return 'row-processing';
                  case 'success':
                    return 'row-success';
                  case 'error':
                    return 'row-error';
                  default:
                    return '';
                }
              }}
            />
          </div>
          <div className="progress-summary">
            {showSummary ? (
              processing ? (
                <div>
                  <p>
                    Processing: {progress.successCount + progress.errorCount}/
                    {progress.total}
                  </p>
                </div>
              ) : (
                <p>
                  Disabling {progress.successCount} users, {progress.errorCount}{' '}
                  errors
                </p>
              )
            ) : (
              <></>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleExport}
            color="primary"
            disabled={processing || progress.unprocessed.length === 0}
          >
            <>Export unprocessed</>
          </Button>
          <Button
            onClick={() => void handleUpload()}
            color="primary"
            disabled={
              showSummary ||
              csvData.length === 0 ||
              Object.keys(validationErrors).length > 0
            }
          >
            <>Proceed</>
          </Button>
          <Button
            onClick={() => setOpen(false)}
            color="secondary"
            disabled={processing}
          >
            <>Close</>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
