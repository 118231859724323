import { ZodCsvSchema } from './bulkZodUtils';
import { CsvRecord, GridRow, GridRowValidationError } from './types';

/**
 * Validates a CSV file synchronously using Zod. This is useful for validating CSV files before running async validation
 * @param oldData Previously validated data. This is used to determine if the row has changed and therefore be set to `idle` (and therefore re-trigger async validation), or left as its previous async validation result (e.g. `success` / `error`).
 * @param newData The data to validate
 * @param schema The zod schema to validate the data against. Any validation errors for the CSV as a whole will be ignored. Only errors associated with specific rows will be mapped. For example, if you used `z.array(...).min(10)` in your schema, the function will ignore that `min` error, as it's not row-specific. However, `z.array(z.object({ name: z.string() }))` will return the error for each failing row.
 * @returns The parsed data. If the schema has errors with a row, it will have the status of `error`. Otherwise if the row has been changed, it will have the status "idle". If it has not been changed, it will keep the previous status of validation.
 */
export function validateCsvSync<T extends CsvRecord>(
  oldData: GridRow<T>[],
  newData: T[],
  schema: ZodCsvSchema<T>
): GridRow<T>[] {
  const parsed = schema.safeParse(newData);

  function getRowStatus(
    row: T,
    hasCsvErrors: boolean,
    oldRow: GridRow<T> | undefined
  ) {
    if (hasCsvErrors) {
      return 'error';
    }

    if (!oldRow) {
      return 'idle';
    }

    const rowHasChanged = JSON.stringify(row) !== JSON.stringify(oldRow.data);

    if (rowHasChanged) {
      // Re-run any async validation
      return 'idle';
    }

    // If the row hasn't been updated, we want to preserve the old status to avoid re-running any async validation unnecessarily.
    return oldRow.status;
  }

  const data = newData.map<GridRow<T>>((row, index) => {
    const oldRow = oldData[index];

    const errors =
      parsed.error?.errors
        .filter((e) => e.path[0] === index)
        .map<GridRowValidationError<T>>((e) => ({
          property: e.path.slice(1).join('.') as keyof T,
          message: e.message,
        })) ?? [];

    return {
      id: index,
      data: row,
      status: getRowStatus(row, errors.length > 0, oldRow),
      csvErrors: errors,
      asyncErrors: oldRow?.asyncErrors ?? [],
    };
  });

  return data;
}
