import { useMutation } from '@tanstack/react-query';
import { FC, ReactNode, useState } from 'react';
import {
  Button,
  ButtonProps,
  Confirm,
  useListContext,
  useNotify,
  useResourceContext,
  useTranslate,
} from 'react-admin';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { humanize, inflect } from 'inflection';
import { CircularProgress } from '@mui/material';

interface CustomBulkActionProps {
  mutationFn: (ids: any[]) => Promise<any>;
  resource?: string;
  /**
   * Whether a dialog is presented asking the user to confirm before they can proceed
   */
  requireConfirmation?: boolean;
  label?: string;
  buttonIcon?: ReactNode;
  color?: ButtonProps['color'];
  modalContent?: string;
  modalTitle?: string;
  successMessage?: string;
  errorMessage?: string;
}

/**
 * Create bulk actions for resources that require custom mutation functions. These are usually resources which don't have a primary key (ID), or which have bulk operations beyond the standard "Delete" operation.
 */
export const CustomBulkAction: FC<CustomBulkActionProps> = (props) => {
  const {
    mutationFn,
    label = 'ra.action.delete',
    color,
    buttonIcon = <DeleteIcon />,
    modalContent = 'ra.message.bulk_delete_content',
    modalTitle = 'ra.message.bulk_delete_title',
    successMessage = 'ra.notification.deleted',
    errorMessage = 'ra.notification.http_error',
  } = props;

  const resource = useResourceContext(props);
  const { selectedIds, refetch, onUnselectItems } = useListContext();

  const [isOpen, setIsOpen] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();

  const bulkMutate = useMutation({
    mutationFn,
    onSuccess: (_data, variables) => {
      notify(successMessage, {
        type: 'info',
        messageArgs: { count: variables.length },
      });
    },
    onError: () => {
      notify(errorMessage, {
        type: 'error',
        autoHideDuration: 5000,
      });
    },
    onSettled: () => {
      void refetch();
      onUnselectItems();
    },
  });

  return (
    <>
      {bulkMutate.isPending ? (
        <CircularProgress size="1.5em" />
      ) : (
        <Button
          onClick={() => {
            if (props.requireConfirmation) {
              setIsOpen(true);
            } else {
              bulkMutate.mutate(selectedIds);
            }
          }}
          color={color}
          startIcon={buttonIcon}
          label={label}
        />
      )}
      <Confirm
        isOpen={isOpen}
        loading={bulkMutate.isPending}
        title={modalTitle}
        content={modalContent}
        translateOptions={{
          count: selectedIds.length,
          name: translate(`resources.${resource}.forcedCaseName`, {
            count: selectedIds.length,
            _: humanize(
              translate(`resources.${resource}.name`, {
                count: selectedIds.length,
                _: resource ? inflect(resource, selectedIds.length) : undefined,
              }),
              true
            ),
          }),
        }}
        onConfirm={() => bulkMutate.mutate(selectedIds)}
        onClose={() => setIsOpen(false)}
      />
    </>
  );
};
