/**
 * Sorts an array in place. This method mutates the array and returns a reference to the same array.
 * Supports primary and optional secondary sorting.
 * @param items The collection of items to sort in place
 * @param selector Function used to determine what to sort the elements by
 * @param order Whether to sort items in ascending or descending order
 * @param thenBy Optional secondary sort key selector
 * @param thenOrder Optional secondary sort order (defaults to same as primary)
 * @returns A reference to {@link items}
 */
export const sortBy = <T>(
  items: T[],
  selector: (item: T) => string | number,
  order: 'ascending' | 'descending' = 'ascending',
  thenBy?: (item: T) => string | number,
  thenOrder?: 'ascending' | 'descending'
) => {
  return items.sort((item1, item2) => {
    const key1 = selector(item1);
    const key2 = selector(item2);

    if (key1 < key2) return order === 'ascending' ? -1 : 1;
    if (key1 > key2) return order === 'ascending' ? 1 : -1;

    // If primary keys are equal and thenBy is provided, apply secondary sorting
    if (thenBy) {
      const secondaryKey1 = thenBy(item1);
      const secondaryKey2 = thenBy(item2);
      const secondaryOrder = thenOrder ?? order; // Default to primary order if not provided

      if (secondaryKey1 < secondaryKey2)
        return secondaryOrder === 'ascending' ? -1 : 1;
      if (secondaryKey1 > secondaryKey2)
        return secondaryOrder === 'ascending' ? 1 : -1;
    }

    return 0;
  });
};
