import BlockIcon from '@mui/icons-material/Block';
import DoneIcon from '@mui/icons-material/Done';
import { Alert, CircularProgress } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  Button,
  useDataProvider,
  useListContext,
  useNotify,
  useTranslate,
  useUnselectAll,
} from 'react-admin';
import { AdminDataProvider } from '../../../api/adminDataProvider';
import { ResourcesValue } from '../AdminResources';
import { User } from './types';

export const BulkUserDisableAction: FC = () => {
  const { data, selectedIds, refetch } = useListContext<User>();
  const [selected, setSelected] = useState<
    Array<{ id: number; isDisabled: boolean }>
  >([]);
  const dataProvider = useDataProvider<AdminDataProvider>();
  const translate = useTranslate();
  const notify = useNotify();
  const unselectAll = useUnselectAll(ResourcesValue.USERS);

  // TODO: This needs a refactor since including `selectedIds` in the dependency array causes infinite loop and without it, the values of selectedIds might be outdated
  useEffect(() => {
    const updateSelected = [...selected];

    data!
      .filter((u) => selectedIds.includes(u.id))
      .forEach((user) => {
        const existingSelection = updateSelected.find(
          (sel) => sel.id === user.id
        );
        const newUserSelection = {
          id: user.id,
          isDisabled: !!user.disabledFrom,
        };

        if (existingSelection)
          updateSelected[updateSelected.indexOf(existingSelection)] =
            newUserSelection;
        else updateSelected.push(newUserSelection);
      });

    const filteredSelected = updateSelected.filter((sel) =>
      selectedIds.includes(sel.id)
    );

    setSelected(filteredSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIds, data]);

  const shouldDisableUsers = selected.some((item) => !item.isDisabled);

  const setEnabledUsers = useMutation({
    mutationFn: dataProvider.setUsersEnabled,
    onSuccess: (e) => {
      if (e.failedUserIds.length === 0) {
        notify(
          <Alert severity="success">
            {translate('moonstar.bulk_disable.success', {
              count: e.successfulUserIds.length,
            })}
          </Alert>
        );
      } else {
        notify(
          <Alert severity="warning">
            {translate('moonstar.bulk_disable.partial_success', {
              total_success: e.successfulUserIds.length,
              total_failed: e.failedUserIds.length,
            })}
          </Alert>
        );
      }
    },
    onError: () => {
      notify(
        <Alert severity="error">
          {translate('moonstar.bulk_disable.error')}
        </Alert>,
        {
          autoHideDuration: 5000,
        }
      );
    },
    onSettled: () => {
      void refetch();
      unselectAll();
    },
  });

  const enableUsers = useCallback(() => {
    setEnabledUsers.mutate({ userIds: selectedIds, isEnabled: true });
  }, [selectedIds, setEnabledUsers]);

  const disableUsers = useCallback(() => {
    setEnabledUsers.mutate({ userIds: selectedIds, isEnabled: false });
  }, [selectedIds, setEnabledUsers]);

  if (setEnabledUsers.isPending) {
    return <CircularProgress size="1.5em" />;
  }

  if (shouldDisableUsers) {
    return (
      <Button
        onClick={disableUsers}
        color={'error' as 'primary'} // Might break later as this isn't really supported, but it allows us to have a nice red button without writing that out by hand
        startIcon={<BlockIcon />}
        label="moonstar.user.disable.disable"
      />
    );
  } else {
    return (
      <Button
        onClick={enableUsers}
        color="success"
        startIcon={<DoneIcon />}
        label="moonstar.user.disable.enable"
      />
    );
  }
};
