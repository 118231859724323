import { GetUserResponse } from '../../components/admin/User/types';
import { HttpClient } from '../httpClient';

export interface Params {
  id?: string;
  internalId?: string;
}

export const getUser = (httpClient: HttpClient) => async (params: Params) => {
  const definedParams = Object.fromEntries(
    Object.entries(params).filter(
      ([_, value]) => value !== undefined && value !== ''
    )
  ) as Record<string, string>;

  const queryString = new URLSearchParams(definedParams).toString();

  const url = queryString
    ? `/admin/users/getUser?${queryString}`
    : '/admin/users/getUser';

  const response = await httpClient.api(url, { method: 'GET' });

  return response.body ? (response.json as GetUserResponse) : undefined;
};
