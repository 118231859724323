import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../adminDataProvider';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export interface Response {
  onboarded: {
    total: number;
    lastSevedDays: number;
    lastThirtyDays: number;
  };
  activated: {
    total: number;
    lastSevedDays: number;
    lastThirtyDays: number;
  };
  engaged: {
    lastSevedDays: number;
    previousSevenDays: number;
    lastThirtyDays: number;
  };
}

export const useUsersWidgetsData = (
  options?: UseQueryOptions<Response, Error, Response>
) => {
  const dataProvider = useDataProvider<AdminDataProvider>();

  return useQuery<Response>({
    queryKey: ['getUsersWidgetsData'],
    queryFn: () => dataProvider.getUsersWidgetsData(),
    staleTime: 1000 * 60,
    ...options,
  });
};
