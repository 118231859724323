import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAuthState, useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../api/adminDataProvider';
import { useQuery } from '@tanstack/react-query';

export type UserRole = 'Admin' | 'User';

export const useGetUserRole = (options?: { enabled?: boolean }) => {
  const provider = useDataProvider<AdminDataProvider>();

  return useQuery({
    queryKey: ['fetch-user-role'],
    queryFn: provider.fetchUserRole,
    staleTime: 1000 * 60,
    ...(options ?? {}),
  });
};

const UserRoleContext = createContext<UserRole>('User');

export const UserRoleProvider = ({ children }: { children: ReactNode }) => {
  const { isPending, authenticated } = useAuthState();

  const options = {
    enabled: isPending ? false : authenticated,
  };
  const { data: initialUserRole } = useGetUserRole(options);

  const [userRole, setUserRole] = useState<UserRole>('User');

  useEffect(() => {
    if (typeof initialUserRole !== 'string') return;

    setUserRole(initialUserRole || 'User');
  }, [initialUserRole]);

  return (
    <UserRoleContext.Provider value={userRole}>
      {children}
    </UserRoleContext.Provider>
  );
};

export const useUserRole = () => useContext(UserRoleContext);
export const useHasRole = (role: UserRole) => useUserRole() === role;
