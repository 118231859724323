import React from 'react';
import { Grid } from '@mui/material';
import { useFeedWidgetsData } from '../../../../api/hooks/useFeedWidgetsData';
import { Widget } from '../Widget';

interface FeedWidgetsProps {
  after: string;
  before: string;
}

export const FeedWidgets: React.FC<FeedWidgetsProps> = ({ after, before }) => {
  const { data } = useFeedWidgetsData({ after, before });

  const { posts, comments, reactions } = data ?? {
    posts: { total: 0, lastSevenDays: 0 },
    comments: { total: 0, lastSevenDays: 0 },
    reactions: { total: 0, lastSevenDays: 0 },
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{ marginBottom: 2, marginTop: 0 }}
        alignItems="stretch"
      >
        <Grid item xs={12} md={4}>
          <Widget
            title={'Created Posts'}
            textPrimary={posts.total.toString()}
            textSecondary={`${posts.lastSevenDays} in previous seven days`}
            description={'Total number of posts created during selected period'}
            color="primary"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Widget
            title={'Created Comments'}
            textPrimary={comments.total.toString()}
            textSecondary={`${comments.lastSevenDays} in previous seven days`}
            description={'Total number of comments made during selected period'}
            color="primary"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Widget
            title={'Reactions'}
            textPrimary={reactions.total.toString()}
            textSecondary={`${reactions.lastSevenDays} in previous seven days`}
            description={
              'Total number of reactions added during selected period'
            }
            color="primary"
          />
        </Grid>
      </Grid>
    </>
  );
};
