import { z } from 'zod';
import { CsvRecord } from './types';

/**
 * Checks for duplicate values in a list of objects. For use from a `superRefine` on a Zod array schema
 * @example
 * ```ts
 * const schema = z
 *   .object({
 *     name: z.string(),
 *   })
 *   .array()
 *   .superRefine((...args) => superRefineDuplicateProps('name', ...args));
 * ```
 */
export function superRefineDuplicateProps<T>(
  key: string & keyof T,
  values: T[],
  ctx: z.RefinementCtx
) {
  const duplicateIndexes = new Set<number>();

  values.forEach((r, i) => {
    if (duplicateIndexes.has(i)) {
      return;
    }

    const newDuplicateIndexes = values
      .map((row, idx) => (row[key] === r[key] ? idx : -1))
      .filter((row) => row !== -1);

    if (newDuplicateIndexes.length <= 1) {
      return;
    }

    newDuplicateIndexes.forEach((idx) => {
      duplicateIndexes.add(idx);

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Duplicate ${key} found in rows: ${newDuplicateIndexes
          .map((idx) => idx + 1 /* 1-based index */)
          .join(', ')}`,
        path: [idx, key],
      });
    });
  });
}

type ZodCsvRow<TOutput extends CsvRecord> =
  | z.ZodObject<any, any, any, TOutput>
  | z.ZodEffects<ZodCsvRow<TOutput>, any, TOutput>;

type ZodCsvRows<TOutput extends CsvRecord> = z.ZodArray<ZodCsvRow<TOutput>>;
export type ZodCsvSchema<TOutput extends CsvRecord> =
  | ZodCsvRows<TOutput>
  | z.ZodEffects<ZodCsvSchema<TOutput>, any, TOutput[]>;

export const getZodCsvRowObjectSchema = <TOutput extends CsvRecord>(
  schema: ZodCsvSchema<TOutput>
): z.ZodObject<any, any, any, TOutput> => {
  while (schema instanceof z.ZodEffects) {
    schema = schema.sourceType();
  }

  let elementSchema = schema.element;
  while (elementSchema instanceof z.ZodEffects) {
    elementSchema = elementSchema.sourceType();
  }

  return elementSchema;
};
