import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';
import useLocalization from '@sendbird/uikit-react/hooks/useLocalization';
import { UserDto } from '../../../../api/customEndpoints/fetchAllUsers';
import { SearchTextInput } from '../SearchTextInput';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Typography,
} from '@mui/material';
import { User, UserList } from '../UserList';
import { useTranslate } from 'react-admin';
import { useFetchChannelMembers } from '../../../../api/hooks/sendbird/useFetchChannelMembers';
import { ChannelUserCard } from '../ChannelUserCard';
import { Role } from '@sendbird/chat';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UserListItemProps } from '@sendbird/uikit-react/ui/UserListItem';
import { useFetchChannelOperators } from '../../../../api/hooks/sendbird/useFetchChannelOperators';
import AllMembersModalDotsMenu from './AllMembersModalDotsMenu';
import { useFetchMutedUsers } from '../../../../api/hooks/sendbird/useFetchMutedUsers';
import Icon from '@sendbird/uikit-react/ui/Icon';

export interface AllMembersModalProps {
  open: boolean;
  onCancel(): void;
  renderUserListItem?: (
    props: UserListItemProps & { index: number }
  ) => ReactNode;
}

export function AllMembersModal({ onCancel, open }: AllMembersModalProps) {
  const translate = useTranslate();
  const { channel } = useChannelSettingsContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [selectedUserForMenu, setSelectedUserForMenu] = useState<User | null>(
    null
  );
  const [progress, setProgress] = useState({
    fetched: 0,
    total: channel?.memberCount,
  });
  const { data: channelOperators } = useFetchChannelOperators();
  const { data: mutedUsers } = useFetchMutedUsers();
  const { data: channelMembers, isFetching } = useFetchChannelMembers(
    (fetched, total) => {
      setProgress({ fetched, total });
    }
  );
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<UserDto[]>([]);

  const progressPercentage = progress.total
    ? (progress.fetched * 100) / progress.total
    : 0;

  const isUserChannelOperator = useCallback(
    (userId: string) => {
      if (!channelOperators) {
        return false;
      }
      return channelOperators.map((op) => op.userId).includes(userId);
    },
    [channelOperators]
  );

  const isMutedUser = useCallback(
    (userId: string) => {
      if (!mutedUsers) {
        return false;
      }
      return mutedUsers.map((mu) => mu.userId).includes(userId);
    },
    [mutedUsers]
  );

  const channelMemberIds = useMemo(() => {
    if (channelMembers === undefined) {
      return [];
    }

    return channelMembers.map((m) => Number(m.userId));
  }, [channelMembers]);

  const { stringSet } = useLocalization();

  const containerRef = useRef(null);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    user: User
  ) => {
    setSelectedUserForMenu(user);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setSelectedUserForMenu(null);
    setAnchorEl(null);
  };

  return (
    <>
      <Dialog scroll="paper" open={open} onClose={onCancel} fullWidth>
        <DialogTitle sx={{ paddingX: 2, paddingY: 1 }}>
          {translate('chat.allMembersModalTitle')}
          <SearchTextInput
            label="Search for members"
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
          />
        </DialogTitle>
        <DialogContent sx={{ padding: 0 }} ref={containerRef}>
          <UserList
            searchTerm={searchTerm}
            userIdsFilter={channelMemberIds}
            containerRef={containerRef}
            renderUser={(u) => {
              return (
                <ChannelUserCard
                  user={u}
                  selectedUsers={selectedUsers}
                  setSelectedUsers={setSelectedUsers}
                  disableRipple
                  right={
                    <>
                      {isUserChannelOperator(u.userId) ? (
                        <Typography variant="inherit" color="GrayText">
                          Operator
                        </Typography>
                      ) : undefined}
                      {isMutedUser(u.userId) ? (
                        <div style={{ alignSelf: 'center' }}>
                          <Icon height={32} type="MUTE" fillColor="PRIMARY" />
                        </div>
                      ) : undefined}
                      {channel?.myRole === Role.OPERATOR ? (
                        <IconButton
                          color="primary"
                          onClick={(event) => handleMenuClick(event, u)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      ) : undefined}
                    </>
                  }
                />
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          {isFetching && (
            <Typography
              sx={{ marginRight: 'auto' }}
              variant="caption"
              color="primary"
            >
              {translate('chat.loadingMembers', {
                progressPercentage: progressPercentage.toFixed(2),
              })}
            </Typography>
          )}
          <Button variant="outlined" onClick={onCancel}>
            {stringSet.BUTTON__CANCEL}
          </Button>
        </DialogActions>
        {isFetching && (
          <LinearProgress variant="determinate" value={progressPercentage} />
        )}
      </Dialog>
      {/* Operator menu when clicking three dots menu icon on a user card */}
      {selectedUserForMenu && (
        <AllMembersModalDotsMenu
          user={selectedUserForMenu}
          openMenu={openMenu}
          anchorEl={anchorEl}
          onMenuClose={handleMenuClose}
        />
      )}
    </>
  );
}
