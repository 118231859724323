import { HttpClient } from '../httpClient';

export const setUserEnabled =
  (httpClient: HttpClient) => async (userId: string, isEnabled: boolean) => {
    const body = {
      isEnabled: isEnabled,
    };

    return await httpClient.api(`/admin/users/${userId}/enabled`, {
      method: 'PUT',
      body: JSON.stringify(body),
    });
  };
