import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useNotify, useTranslate } from 'react-admin';

type MuteUserParams = {
  id: string;
  channel: GroupChannel;
};

const muteUser = async ({ id, channel }: MuteUserParams) => {
  return await channel.muteUserWithUserId(id);
};

export const useMuteUser = (
  options?: UseMutationOptions<unknown, Error, MuteUserParams>
) => {
  const notify = useNotify();
  const translate = useTranslate();

  return useMutation({
    mutationKey: ['mute-user'],
    mutationFn: muteUser,
    ...options,
    onError: () => {
      notify(translate('chat.muteUserError'), {
        autoHideDuration: 5000,
      });
    },
  });
};
