import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';
import { useQuery } from '@tanstack/react-query';
import { User } from '@sendbird/chat';

const fetchChannelOperators = async (
  channel: GroupChannel | null,
  onProgress?: (fetched: number, total: number) => void
) => {
  if (!channel) return [];

  const operatorListQuery = channel.createOperatorListQuery({
    limit: 100,
  });

  let allOperators: User[] = [];
  while (operatorListQuery.hasNext) {
    const operators = await operatorListQuery.next();
    allOperators = [...allOperators, ...operators];

    if (onProgress) {
      onProgress(allOperators.length, channel.memberCount);
    }
  }

  return allOperators;
};

export const useFetchChannelOperators = (
  onProgress?: (fetched: number, total: number) => void
) => {
  const { channel } = useChannelSettingsContext();

  return useQuery<User[]>({
    queryKey: ['channel-operators', channel],
    queryFn: () => fetchChannelOperators(channel, onProgress),

    // Not sure if this will need to be revalidated when the member list changes (such as after an invite)
    staleTime: 60 * 1000, // 1 minute
  });
};
