import { useTranslate } from 'react-admin';
import { Box, Dialog, DialogTitle } from '@mui/material';
import { BulkOperationDialogGetTemplate } from './BulkOperationDialogGetTemplate';
import { useCallback, useEffect, useState } from 'react';
import { BulkOperationDialogUpload } from './BulkOperationDialogUpload';
import { BulkOperationDialogGrid } from './BulkOperationDialogGrid';
import { CsvRecord, GridRowValidator, RowProcessor } from './types';
import { BulkOperationDialogProcess } from './BulkOperationDialogProcess';
import {
  BulkOperationDialogStepper,
  BulkOperationDialogSteps,
} from './BulkOperationDialogStepper';
import { ZodCsvSchema } from './bulkZodUtils';

export interface BulkOperationDialogProps<T extends CsvRecord> {
  label: string;
  resource: string;
  open: boolean;
  onClose: () => void;
  /**
   * Makes sure that the properties in this schema will be included in the exported CSV.
   */
  schema: ZodCsvSchema<T>;
  /**
   * Example values to be included in the exported CSV.
   */
  exampleValues?: T[];

  validateRow?: GridRowValidator<T>;
  validationRateLimitDelay: number;

  processRow: RowProcessor<T>;
  processingRateLimitDelay: number;
}

function confirmDiscardGrid() {
  return confirm('Discard changes?');
}

function confirmStopProcessing() {
  return confirm('Stop processing?');
}

export function BulkOperationDialog<T extends CsvRecord>({
  open,
  onClose,
  label,
  resource,
  validateRow,
  schema,
  exampleValues,
  validationRateLimitDelay,
  processRow,
  processingRateLimitDelay,
}: BulkOperationDialogProps<T>) {
  const translate = useTranslate();
  const [stage, setStage] = useState<BulkOperationDialogSteps>('get-template');
  const [csvData, setCsvData] = useState<T[]>([]);

  const handleClose = useCallback(() => {
    if (stage === 'grid' && !confirmDiscardGrid()) {
      return;
    } else if (stage === 'process' && !confirmStopProcessing()) {
      return;
    }

    onClose();
  }, [stage, onClose]);

  useEffect(() => {
    function handler(event: BeforeUnloadEvent) {
      if (stage === 'grid' || stage === 'process') {
        event.preventDefault();
      }
    }

    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, [stage]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={stage === 'grid' ? 'xl' : 'lg'}
    >
      <Box sx={{ display: 'flex' }}>
        <BulkOperationDialogStepper step={stage} />
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            minWidth: 400,
          }}
        >
          <DialogTitle>{translate(label)}</DialogTitle>

          {stage === 'get-template' && (
            <BulkOperationDialogGetTemplate
              label={label}
              resource={resource}
              onBack={onClose}
              onNext={() => setStage('upload')}
              schema={schema}
              exampleValues={exampleValues}
            />
          )}
          {stage === 'upload' && (
            <BulkOperationDialogUpload
              onBack={() => setStage('get-template')}
              onNext={(data) => {
                setCsvData(data as T[]);
                setStage('grid');
              }}
            />
          )}
          {stage === 'grid' && (
            <BulkOperationDialogGrid
              onBack={() => {
                if (confirmDiscardGrid()) {
                  setStage('upload');
                }
              }}
              onNext={(data) => {
                setCsvData(data);
                setStage('process');
              }}
              label={label}
              data={csvData}
              schema={schema}
              validationRateLimitDelay={validationRateLimitDelay}
              validateRow={validateRow}
            />
          )}
          {stage === 'process' && (
            <BulkOperationDialogProcess
              label={label}
              data={csvData}
              processRow={processRow}
              processingRateLimitDelay={processingRateLimitDelay}
              onNext={onClose}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
}
