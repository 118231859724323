import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../adminDataProvider';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export interface ChartParams {
  after: string;
  before: string;
}

export interface Response {
  onboarded: {
    date: string;
    count: number;
  }[];
  activated: {
    date: string;
    count: number;
  }[];
  engaged: {
    date: string;
    count: number;
    onboardedCount: number;
    ids: number[] | undefined;
    percentage: number;
  }[];
}

export interface Entry {
  date: string;
  count: number;
  percentage: number;
}

export const useUsersChartData = (
  params: ChartParams,
  options?: UseQueryOptions<Response, Error, Response>
) => {
  const dataProvider = useDataProvider<AdminDataProvider>();

  return useQuery<Response>({
    queryKey: ['getUsersChartData', params],
    queryFn: () => dataProvider.getUsersChartData(params),
    staleTime: 1000 * 60,
    ...options,
  });
};
