import {
  Button,
  ChipField,
  Datagrid,
  ImageField,
  Link,
  NumberField,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  useDataProvider,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { MoonstarList } from '../Moonstar/MoonstarList';
import CollectionsIcon from '@mui/icons-material/Collections';
import CommentIcon from '@mui/icons-material/Comment';
import {
  shrinkFirstColumn,
  shrinkLastColumn,
} from '../../../utils/shrinkLastColumn';
import { CircularProgress } from '@mui/material';
import { Assessment } from '@mui/icons-material';
import { AdminDataProvider } from '../../../api/adminDataProvider';
import { useMutation } from '@tanstack/react-query';
import { saveCsv } from '../BulkOperation/saveCsv';

export const NudgeList = () => {
  return (
    <MoonstarList>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={false}
        sx={[shrinkLastColumn, shrinkFirstColumn]}
      >
        <ImageField source="image.src" title="image.title" />
        <TextField source="title" />
        <TextField source="description" />
        <TextField source="hintDescription" />
        <NumberField source="duration" />
        <ReferenceArrayField
          source="categoryIds"
          reference="nudgeCategories"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          source="cohortIds"
          reference="cohorts"
          sortable={false}
        >
          <SingleFieldList linkType={false} empty={<p>No cohorts assigned</p>}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ViewNudgeSlidesButton />
      </Datagrid>
    </MoonstarList>
  );
};

const ViewNudgeSlidesButton = () => {
  const record = useRecordContext();

  return (
    // Fixes `Button` propagating the link up to the table and navigating twice
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()}>
      <Button
        component={Link}
        to={`/nudges/${record?.id}/slides`}
        startIcon={<CollectionsIcon />}
        label="resources.nudges.fields.nudgeSlides"
      />
      <Button
        component={Link}
        to={`/nudges/${record?.id}/feedbacks`}
        startIcon={<CommentIcon />}
        label="resources.nudges.fields.nudgeFeedbacks"
      />
      <GetNudgeReportButton />
    </div>
  );
};

const GetNudgeReportButton = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const dataProvider = useDataProvider<AdminDataProvider>();
  const getReport = useMutation({
    mutationFn: async () => {
      const id = record?.id;
      if (!id) {
        throw new Error('Record context is not available');
      }

      const res = await dataProvider.getNudgesReport(id);

      saveCsv({
        data: res.data.map((r) => ({
          ['Nudge ID']: r.nudgeId,
          Categories: r.categories,
          ['Nudge Title']: r.nudgeTitle,
          ['Nudge Created On']: r.nudgeCreatedOn,
          ['Nudge Last Edited On']: r.nudgeLastEditedOn,
          ['Employee ID']: r.employeeId,
          ['Employee Internal ID']: r.employeeInternalId ?? undefined,
          ['Employee Name']: r.employeeName,
          ['Job Title']: r.jobTitle,
          Department: r.department,
          Region: r.region,
          Units: r.units,
          ['Total Slides']: r.totalSlides,
          ['First Accessed On']: r.firstAccessedOn ?? undefined,
          ['Last Accessed On']: r.lastAccessedOn ?? undefined,
          ['Accessed Slides']: r.accessedSlides,
          Completed: r.completed,
          Rating: r.rating ?? undefined,
          ['Is Interesting Rating']: r.isInterestingRating ?? undefined,
        })),
        basename: `${new Date()
          .toISOString()
          .substring(0, '2000-01-01'.length)} - Nudges report`,
      });
    },
    onError: (error) => {
      notify(`The requested report could not be downloaded: ${error.message}`, {
        type: 'error',
      });
    },
  });

  return (
    <Button
      label="Report"
      onClick={() => getReport.mutate()}
      disabled={getReport.isPending}
    >
      {getReport.isPending ? <CircularProgress size={16} /> : <Assessment />}
    </Button>
  );
};
