import { useCallback, useState } from 'react';
import { RenderEditCellProps } from 'react-data-grid';
import { CsvRecord, GridRow } from './types';

export function BulkOperationDialogGridEditCell<
  TRow extends CsvRecord,
  TSummaryRow,
>({
  row,
  column,
  onRowChange,
  onClose,
}: RenderEditCellProps<GridRow<TRow>, TSummaryRow>) {
  const [localValue, setLocalValue] = useState<string>(
    row.data[column.key as keyof TRow] as unknown as string
  );

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setLocalValue(event.target.value);
    },
    []
  );

  const commitRowChanges = useCallback(() => {
    onRowChange(
      {
        ...row,
        status: 'idle',
        data: {
          ...row.data,
          [column.key]: localValue,
        },
      },
      true
    );
    onClose(true, false);
  }, [row, column, localValue, onRowChange, onClose]);

  const handleKeyDown = useCallback<
    React.KeyboardEventHandler<HTMLInputElement>
  >(
    (event) => {
      if (event.key === 'Enter') {
        commitRowChanges();
      } else if (event.key === 'Escape') {
        onClose(false, false);
      }
    },
    [commitRowChanges, onClose]
  );

  const autoFocusAndSelectRef = useCallback<
    React.RefCallback<HTMLInputElement>
  >((input: HTMLInputElement | null) => {
    input?.focus();
    input?.select();
  }, []);

  return (
    <input
      className="textEditor rdg-cell"
      ref={autoFocusAndSelectRef}
      value={localValue}
      onChange={handleChange}
      onBlur={commitRowChanges}
      onKeyDown={handleKeyDown}
    />
  );
}
