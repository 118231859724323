import React, { useMemo, useState } from 'react';
import { FeedWidgets } from './Widgets';
import { FeedChart } from './Chart';
import { useTranslate } from 'react-admin';
import { MoonstarHelmet } from '../../Moonstar/MoonstarHelmet';

export const FeedAnalytics: React.FC = () => {
  const translate = useTranslate();

  const now = useMemo(() => new Date(), []);

  const [after, setAfter] = useState(() => {
    const thirtyDaysAgo = new Date(now);
    thirtyDaysAgo.setDate(now.getDate() - 30);

    return thirtyDaysAgo.toISOString().split('T')[0]!;
  });

  const [before, setBefore] = useState(() => now.toISOString().split('T')[0]!);

  const analyticsLabel = translate('moonstar.analytics.name');
  const feedLabel = translate('resources.posts.name', {
    count: 2,
  });
  const appName = translate('moonstar.appName');

  return (
    <>
      <MoonstarHelmet>
        <title>
          {feedLabel} | {analyticsLabel} | {appName}
        </title>
      </MoonstarHelmet>
      <FeedWidgets after={after} before={before} />
      <FeedChart
        after={after}
        before={before}
        setAfter={setAfter}
        setBefore={setBefore}
      />
    </>
  );
};
