import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';

type CsvRecord = Record<string, string>;

export async function readCsvExcelFile(file: File): Promise<CsvRecord[]> {
  if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
    return xlsxToJson(await file.arrayBuffer());
  } else {
    const fileContents = Papa.parse<CsvRecord>(await file.text(), {
      header: true,
      skipEmptyLines: 'greedy',
    });

    return fileContents.data;
  }
}

function xlsxToJson(buffer: ArrayBuffer) {
  const xlsxFile = XLSX.read(buffer, {
    cellDates: true, // Converts Excel dates (number) to JS dates (Date)
  });

  const [firstSheetName] = xlsxFile.SheetNames;
  if (firstSheetName === undefined) {
    // No sheets in the file
    return [];
  }
  const firstSheet = xlsxFile.Sheets[firstSheetName];
  if (firstSheet === undefined) {
    // No sheets in the file
    return [];
  }

  const fileContents = XLSX.utils
    .sheet_to_json(firstSheet, {
      header: undefined, // Use the first row as header
      blankrows: false, // Ignore blank rows
    })
    .map((row) =>
      Object.entries(row as object).reduce<CsvRecord>((acc, [key, value]) => {
        if (value instanceof Date) {
          acc[key] = format(value, 'dd/MM/yyyy');
        } else {
          acc[key] = value.toString().trim();
        }

        return acc;
      }, {})
    );

  return fileContents;
}
