import { useCallback, useMemo } from 'react';
import { Button, useTranslate } from 'react-admin';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { CsvColumns, saveCsv } from './saveCsv';
import { CsvRecord } from './types';
import { InfoOutlined } from '@mui/icons-material';
import { ZodCsvSchema, getZodCsvRowObjectSchema } from './bulkZodUtils';

interface BulkOperationDialogGetTemplateProps<T extends CsvRecord> {
  label: string;
  resource: string;
  onBack: () => void;
  onNext: () => void;
  /**
   * Makes sure that the properties in this schema will be included in the exported CSV.
   */
  schema?: ZodCsvSchema<T>;
  /**
   * Example values to be included in the exported CSV.
   */
  exampleValues?: T[];
}

export function BulkOperationDialogGetTemplate<T extends CsvRecord>({
  onBack,
  onNext,
  label,
  resource,
  schema,
  exampleValues,
}: BulkOperationDialogGetTemplateProps<T>) {
  const translate = useTranslate();

  const keys = useMemo(() => {
    if (!schema) return undefined;

    const rowSchema = getZodCsvRowObjectSchema(schema);
    return Object.keys(rowSchema.shape) as CsvColumns<T>;
  }, [schema]);

  const downloadTemplate = useCallback(() => {
    saveCsv({
      data: exampleValues ?? [],
      basename: `${translate(label)} - Template`,
      columns: keys,
    });
  }, [keys, exampleValues, label, translate]);

  return (
    <>
      <DialogContent>
        <ol
          style={{
            listStylePosition: 'inside',
            paddingInlineStart: 0,
            lineHeight: 2.5,
          }}
        >
          <li>
            <div
              style={{ display: 'inline-flex', alignItems: 'center', gap: 8 }}
            >
              <DialogContentText component="span">
                <b>Download</b> the Excel template
              </DialogContentText>
              <Button
                label="moonstar.user.exportTemplate.button"
                onClick={downloadTemplate}
                startIcon={<GetAppIcon style={{ fontSize: '20' }} />}
              />
            </div>
          </li>
          <li>
            <DialogContentText component="span">
              Add the{' '}
              <b>{translate(`resources.${resource}.name`, { count: 2 })}</b>{' '}
              you'd like to process
            </DialogContentText>
          </li>
          <li>
            <DialogContentText component="span">
              Save the file as a <b>CSV</b> on your computer
            </DialogContentText>
          </li>
        </ol>
        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            gap: 0.5,
          }}
        >
          <InfoOutlined />{' '}
          <DialogContentText variant="caption">
            Dates will be processed using the format DD/MM/YYYY
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button label="Cancel" color="secondary" onClick={() => onBack()} />
        <Button
          label="Next"
          color="primary"
          variant="contained"
          onClick={onNext}
        />
      </DialogActions>
    </>
  );
}
