import {
  AutocompleteArrayInput,
  AutocompleteInput,
  DateInput,
  ImageField,
  Loading,
  ReferenceArrayInput,
  ReferenceInput,
  TextInput,
  required,
  useDataProvider,
  useGetOne,
  useGetRecordId,
  useTranslate,
} from 'react-admin';
import { MoonstarEdit } from '../Moonstar/MoonstarEdit';
import { Divider, Stack, Typography } from '@mui/material';
import { SimpleStack } from '../Moonstar/SimpleStack';
import { UserEditFormToolbar } from './UserEditFormToolbar';
import { MoonstarEditForm } from '../Moonstar/MoonstarEditForm';
import { phoneNumber } from '../../../utils/validation';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';

export const UserEdit = () => {
  const translate = useTranslate();
  const recordId = useGetRecordId();
  const { data: record, isLoading } = useGetOne('users', {
    id: recordId,
    meta: { force: true },
  });

  const dataProvider = useDataProvider();

  const validatePhoneNumberExists = async (value: string, recordId: string) => {
    if (!value) return undefined;

    try {
      const res = await dataProvider.getUserByPhoneNumber(value);
      if (res && res.id.toString() !== recordId) {
        return `This phone number is already taken by user: ${res.firstName} ${res.lastName}`;
      }
    } catch (error) {
      console.error('Phone number validation error:', error);
      return 'Error checking phone number availability';
    }
    return undefined;
  };

  const validateInternalIdExists = async (value: string, recordId: string) => {
    if (!value) return undefined;

    try {
      const res = await dataProvider.getUserByInternalId(value);
      if (res && res.id.toString() !== recordId) {
        return `This internal identifier is already taken by user: ${res.firstName} ${res.lastName}`;
      }
    } catch (error) {
      console.error('Internal ID validation error:', error);
      return 'Error checking internal identifier availability';
    }
    return undefined;
  };

  if (isLoading || !record) return <Loading />;

  return (
    <MoonstarEdit redirect="list">
      <MoonstarEditForm toolbar={<UserEditFormToolbar />}>
        <Stack
          divider={<Divider orientation="vertical" flexItem />}
          flexWrap="wrap"
          direction="row"
          useFlexGap
          spacing={2}
        >
          <SimpleStack>
            <Typography variant="h5" gutterBottom>
              {translate('moonstar.user.edit.section.personalDetails')}
            </Typography>
            <ImageField
              source="pictureUrl"
              title="Profile picture"
              sx={{ '& img': { objectPosition: 'left' } }}
            />
            <TextInput source="firstName" validate={required()} />
            <TextInput source="lastName" validate={required()} />
            <PhoneNumberEdit
              disabledFrom={record.disabledFrom}
              initialValue={record.phoneNumber}
              signIn={record.signIn.phoneNumber}
              recordId={recordId}
              validatePhoneNumberExists={validatePhoneNumberExists}
            />
            <EmailEdit
              disabledFrom={record.disabledFrom}
              initialValue={record.email}
              signIn={record.signIn.email}
            />
          </SimpleStack>

          <SimpleStack>
            <Typography variant="h5" gutterBottom>
              {translate('moonstar.user.edit.section.companyRelatedInfo')}
            </Typography>
            <TextInput
              source="internalId"
              label="Internal ID"
              validate={[
                async (value) =>
                  validateInternalIdExists(value, recordId.toString()),
              ]}
              parse={(v) => (v as string | null) ?? undefined}
            />
            <ReferenceInput
              source="jobTitleId"
              reference="jobTitles"
              perPage={999}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteInput
                margin="none"
                optionText="name"
                validate={required()}
                filterToQuery={(searchText) => ({ name: searchText })}
              />
            </ReferenceInput>
            <ReferenceInput
              source="departmentId"
              reference="departments"
              perPage={999}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteInput
                margin="none"
                optionText="name"
                validate={required()}
                filterToQuery={(searchText) => ({ name: searchText })}
              />
            </ReferenceInput>
            <ReferenceInput
              source="regionId"
              reference="regions"
              perPage={999}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteInput
                margin="none"
                optionText="name"
                validate={required()}
                filterToQuery={(searchText) => ({ name: searchText })}
              />
            </ReferenceInput>
            <ReferenceArrayInput
              source="unitIds"
              reference="units"
              perPage={999}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteArrayInput
                margin="none"
                optionText="name"
                validate={required()}
                filterToQuery={(searchText) => ({ name: searchText })}
              />
            </ReferenceArrayInput>
            <DateInput
              source="startDate"
              validate={required()}
              parse={parseDate}
            />
          </SimpleStack>

          <SimpleStack>
            <Typography variant="h5" gutterBottom>
              {translate('moonstar.user.edit.section.profileSettings')}
            </Typography>
            <TextInput source="language" disabled={true} />
          </SimpleStack>
        </Stack>
      </MoonstarEditForm>
    </MoonstarEdit>
  );
};

const parseDate = (value: string | number | Date) => {
  const date = new Date(value);

  return isNaN(date.getTime()) ? null : date.toISOString();
};

type SignInUserProps = {
  disabledFrom?: string;
  initialValue?: string;
  signIn?: {
    enabled: boolean;
    value?: string;
  };
};

type PhoneSignInUserProps = SignInUserProps & {
  recordId: any;
  validatePhoneNumberExists: (
    value: string,
    recordId: string
  ) => Promise<string | undefined>;
};

const PhoneNumberEdit = ({
  disabledFrom,
  initialValue,
  signIn,
  recordId,
  validatePhoneNumberExists,
}: PhoneSignInUserProps) => {
  const userDisabled = disabledFrom != undefined;
  const phoneValidation = signIn?.enabled
    ? [
        required('moonstar.user.edit.phoneNumber.requiredText'),
        phoneNumber(),
        async (value: any) =>
          await validatePhoneNumberExists(value, recordId.toString()),
      ]
    : [
        phoneNumber(),
        async (value: any) =>
          await validatePhoneNumberExists(value, recordId.toString()),
      ];

  if (signIn?.enabled) {
    const phoneNumberMatches = initialValue === signIn.value;

    const helperText = userDisabled ? undefined : phoneNumberMatches ? (
      'moonstar.user.edit.phoneNumber.enabledHelperText'
    ) : (
      <NotMatchingSignInHelperText value={signIn.value} />
    );

    return (
      <TextInput
        source="phoneNumber"
        validate={phoneValidation}
        helperText={helperText}
        InputProps={{ endAdornment: <VpnKeyOutlinedIcon /> }}
      />
    );
  } else {
    const helperText = userDisabled
      ? undefined
      : 'moonstar.user.edit.phoneNumber.disabledHelperText';
    return (
      <TextInput
        source="phoneNumber"
        validate={phoneValidation}
        helperText={helperText}
      />
    );
  }
};

const EmailEdit = ({ disabledFrom, initialValue, signIn }: SignInUserProps) => {
  const userDisabled = disabledFrom != undefined;

  if (signIn?.enabled) {
    const emailMatches = initialValue === signIn.value;
    const helperText = userDisabled ? undefined : emailMatches ? (
      'moonstar.user.edit.email.enabledHelperText'
    ) : (
      <NotMatchingSignInHelperText value={signIn.value} />
    );
    return (
      <TextInput
        source="email"
        disabled
        helperText={helperText}
        InputProps={{ endAdornment: <VpnKeyOutlinedIcon /> }}
      />
    );
  } else {
    const helperText = userDisabled
      ? undefined
      : 'moonstar.user.edit.email.disabledHelperText';
    return <TextInput source="email" disabled helperText={helperText} />;
  }
};

const NotMatchingSignInHelperText = ({
  value,
}: {
  value: string | undefined;
}) => {
  const translate = useTranslate();
  const part1 = translate('moonstar.user.edit.signIn.notMatchingPart1');
  const part2 = translate('moonstar.user.edit.signIn.notMatchingPart2');
  return (
    <>
      {part1} <b>{value}</b> {part2}
    </>
  );
};
