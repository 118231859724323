import { useMemo } from 'react';
import { UserDto } from '../../../api/customEndpoints/fetchAllUsers';
import { ChannelUserCard } from './ChannelUserCard';
import { ChannelUserCardCheckbox } from './ChannelUserCardCheckbox';
import { User } from './UserList';

type ChannelUserInviteCardProps = {
  user: User;
  selectedUsers: UserDto[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<UserDto[]>>;
  maxUsers: number;
};

export const ChannelUserInviteCard = ({
  user,
  selectedUsers,
  setSelectedUsers,
  maxUsers,
}: ChannelUserInviteCardProps) => {
  const maxReached = useMemo(
    () => selectedUsers.length >= maxUsers,
    [selectedUsers, maxUsers]
  );

  const disabled = useMemo(() => {
    if (!maxReached) return false;
    return selectedUsers.every((u) => u.id !== user.id);
  }, [maxReached, selectedUsers, user.id]);

  return (
    <ChannelUserCard
      user={user}
      selectedUsers={selectedUsers}
      setSelectedUsers={setSelectedUsers}
      disabled={disabled}
      right={
        <ChannelUserCardCheckbox
          user={user}
          selectedUsers={selectedUsers}
          disabled={disabled}
        />
      }
    />
  );
};
