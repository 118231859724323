import { useMemo } from 'react';
import { CsvRecord, GridRow } from './types';
import { Tooltip } from '@mui/material';
import { RenderCellProps } from 'react-data-grid';

export function BulkOperationDialogGridCell<T extends CsvRecord>({
  column: { key },
  row,
}: RenderCellProps<GridRow<T>>) {
  const columnKey = key as keyof T;

  const value = useMemo(() => {
    const val = row.data[columnKey];

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return val === null || val === undefined ? '' : `${val}`;
  }, [row, columnKey]);

  const errorMessage = useMemo(
    () =>
      [...row.csvErrors, ...row.asyncErrors]
        .filter((error) => error.property === columnKey)
        .map((error) => error.message)
        .join(', '),
    [row, columnKey]
  );

  return errorMessage ? (
    <Tooltip title={errorMessage} placement="top" arrow>
      <div className="rdg-cell-error">{value}</div>
    </Tooltip>
  ) : (
    <div>{value}</div>
  );
}
