import '@sendbird/uikit-react/dist/index.css';
import { FC } from 'react';
import { AdminContext, AdminProps } from 'react-admin';
import { UserRoleProvider } from '../auth/userRoleContext';
import { AdminResources } from '../components/admin/AdminResources';
import { SendbirdProviderWithSDK } from '../components/admin/Chat/SendbirdProviderWithSDK';

export const Admin: FC<AdminProps> = (props) => {
  return (
    <AdminContext {...props}>
      <UserRoleProvider>
        <SendbirdProviderWithSDK>
          <AdminResources {...props} />
        </SendbirdProviderWithSDK>
      </UserRoleProvider>
    </AdminContext>
  );
};
