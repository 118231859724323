import { useParams } from 'react-router-dom';
import {
  DateInput,
  ExportButton,
  maxValue,
  minValue,
  useGetOne,
} from 'react-admin';
import { ResourcesValue } from '../AdminResources';
import { CenteredCircularProgress } from '../../custom/CenteredCircularProgress';
import { DateInputError } from './DateInputError';
import { MoonstarList } from '../Moonstar/MoonstarList';
import { MoonstarListActions } from '../Moonstar/MoonstarListActions';
import { badgeAwardDateRangeCheck } from '../../../utils/badgeAwardDateRangeCheck';
import { useState } from 'react';
import { BadgesAwardsList } from './BadgesAwardsList';
import { createListExporter } from './createListExporter';
import { BadgesAwardsChart } from './BadgesAwardsChart';

export const SingleBadgeAnalytics = () => {
  const { id } = useParams();
  const { data: badge, isPending } = useGetOne(ResourcesValue.BADGES, { id });

  // Compute default filters
  const now = new Date();
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  const createdAfterDefault = firstDayOfMonth.toISOString().split('T')[0]!;
  const createdBeforeDefault = lastDayOfMonth.toISOString().split('T')[0]!;

  const [createdAfter, setCreatedAfter] = useState<string>(createdAfterDefault);
  const [createdBefore, setCreatedBefore] =
    useState<string>(createdBeforeDefault);
  const [dateRangeError, setDateRangeError] = useState<string>('');

  const handleDateRangeCheck = (
    createdAfter: string,
    createdBefore: string
  ) => {
    badgeAwardDateRangeCheck(createdAfter, createdBefore, setDateRangeError);
  };

  const handleCreatedAfterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setCreatedAfter(value);
    handleDateRangeCheck(value, createdBefore);
  };

  const handleCreatedBeforeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setCreatedBefore(value);
    handleDateRangeCheck(createdAfter, value);
  };

  if (isPending || !id) {
    return <CenteredCircularProgress />;
  }

  const BasicSingleBadgeAwardsFilters = [
    <DateInput
      source="createdAfter"
      label="Awarded from"
      onChange={handleCreatedAfterChange}
      validate={[maxValue(createdBefore)]}
      alwaysOn
      sx={{ '.MuiInputBase-root': { minHeight: '48px' } }}
    />,
    <DateInput
      source="createdBefore"
      label="Awarded before"
      onChange={handleCreatedBeforeChange}
      validate={[minValue(createdAfter)]}
      alwaysOn
      sx={{ '.MuiInputBase-root': { minHeight: '48px' } }}
    />,
  ];

  return (
    <MoonstarList
      resource={ResourcesValue.BADGE_AWARDS}
      filter={{ badgesId: [id] }}
      filters={BasicSingleBadgeAwardsFilters}
      filterDefaultValues={{
        createdAfter: createdAfterDefault,
        createdBefore: createdBeforeDefault,
      }}
      sort={{ field: 'awardedOn', order: 'DESC' }}
      actions={<MoonstarListActions hideCreate right={<ExportButton />} />}
      exporter={createListExporter(ResourcesValue.BADGE_AWARDS)}
      debounce={1000}
    >
      <DateInputError error={dateRangeError} />
      <BadgesAwardsChart
        name={badge.name}
        title={`Badge awards: ${badge.name}`}
        badgeIdFilter={[id]}
      />
      <BadgesAwardsList />
    </MoonstarList>
  );
};
