import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const GROUP_CHAT_URL_SEARCH_PARAM_KEY = 'channelUrl';

export const useGoToChannel = () => {
  const navigate = useNavigate();

  return useCallback(
    (channelUrl: string) => {
      navigate(
        `/chat?${new URLSearchParams({
          [GROUP_CHAT_URL_SEARCH_PARAM_KEY]: channelUrl,
        })}`
      );
    },
    [navigate]
  );
};
