import { FC, useMemo } from 'react';
import { Menu, MenuItem, Divider, ListSubheader } from '@mui/material';
import { User } from '../UserList';
import { useTranslate } from 'react-admin';
import { useRemoveUser } from '../../../../api/hooks/sendbird/useRemoveUser';
import { useBanUser } from '../../../../api/hooks/sendbird/useBanUser';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';
import { useMuteUser } from '../../../../api/hooks/sendbird/useMuteUser';
import { useRegisterAsOperator } from '../../../../api/hooks/sendbird/useRegisterAsOperator';
import { useFetchChannelMembers } from '../../../../api/hooks/sendbird/useFetchChannelMembers';
import { useFetchBannedChannelUsers } from '../../../../api/hooks/sendbird/useFetchBannedChannelUsers';
import { useFetchChannelOperators } from '../../../../api/hooks/sendbird/useFetchChannelOperators';
import { useUnmuteUser } from '../../../../api/hooks/sendbird/useUnmuteUser';
import { useUnregisterAsOperator } from '../../../../api/hooks/sendbird/useUnregisterAsOperator';

interface AllMembersModalDotsMenuProps {
  user: User;
  openMenu: boolean;
  anchorEl: null | HTMLElement;
  onMenuClose: () => void;
}

const AllMembersModalDotsMenu: FC<AllMembersModalDotsMenuProps> = ({
  user,
  openMenu,
  anchorEl,
  onMenuClose,
}) => {
  const translate = useTranslate();
  const { channel } = useChannelSettingsContext();
  const { data: channelMembers } = useFetchChannelMembers();
  const { data: channelOperators } = useFetchChannelOperators();
  const { data: bannedMembers } = useFetchBannedChannelUsers();
  const { mutate: muteUser } = useMuteUser();
  const { mutate: unmuteUser } = useUnmuteUser();
  const { mutate: banUser } = useBanUser();
  const { mutate: removeUser } = useRemoveUser();
  const { mutate: registerAsOperator } = useRegisterAsOperator();
  const { mutate: unregisterAsOperator } = useUnregisterAsOperator();

  const userChannelMembership = useMemo(() => {
    if (!channelMembers || !bannedMembers || !channelOperators) {
      return null;
    }

    const isMuted = channelMembers.some(
      (member) => member.userId === user.userId && member.isMuted
    );
    const isOperator = channelOperators.some(
      (operator) => operator.userId === user.userId
    );

    return { isMuted, isOperator };
  }, [bannedMembers, channelMembers, channelOperators, user.userId]);

  if (!channel || !userChannelMembership) {
    return null;
  }

  const handleMenuClose = () => {
    onMenuClose();
  };

  const handleOperatorStatus = () => {
    if (userChannelMembership.isOperator) {
      unregisterAsOperator({ id: user.userId, channel });
    } else {
      registerAsOperator({ id: user.userId, channel });
    }

    onMenuClose();
  };

  const handleMuteStatus = () => {
    if (userChannelMembership.isMuted) {
      unmuteUser({ id: user.userId, channel });
    } else {
      muteUser({ id: user.userId, channel });
    }
    onMenuClose();
  };

  const handleKick = () => {
    removeUser({ id: user.userId, channel });
    onMenuClose();
  };

  const handleBan = () => {
    banUser({ id: user.userId, channel });
    onMenuClose();
  };

  return (
    <Menu
      open={openMenu}
      anchorEl={anchorEl}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 0.3,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 15,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <ListSubheader color="primary">{user.displayName}</ListSubheader>
      <MenuItem onClick={handleOperatorStatus}>
        {userChannelMembership.isOperator
          ? translate('chat.unregisterAsOperator')
          : translate('chat.registerAsOperator')}
      </MenuItem>
      <MenuItem onClick={handleMuteStatus}>
        {userChannelMembership.isMuted
          ? translate('chat.unmute')
          : translate('chat.mute')}
      </MenuItem>
      <MenuItem onClick={handleKick}>{translate('chat.kick')}</MenuItem>
      <Divider />
      <MenuItem onClick={handleBan} sx={{ color: 'red' }}>
        {translate('chat.ban')}
      </MenuItem>
    </Menu>
  );
};

export default AllMembersModalDotsMenu;
