import { ClerkProvider } from '@clerk/clerk-react';
import { queryClient } from './App';
import { LoggedInGuard } from './auth/LoggedInGuard';
import { useReactAdminProviders } from './auth/useReactAdminProviders';
import { AppLoading } from './components/AppLoading';
import { useMoonstarI18nProvider } from './i18n/i18nProvider';
import { MoonstarLayout } from './layout/Layout';
import { MOONSTAR_THEME } from './theme';
import { Admin } from './routes/Admin';

const clerkKey = import.meta.env.VITE_CLERK_FRONTEND_API;

export const AdminApp = () => {
  return (
    <ClerkProvider
      signInUrl="/"
      signUpUrl="/"
      publishableKey={clerkKey}
      signInFallbackRedirectUrl="/"
      appearance={{
        variables: {
          colorPrimary: MOONSTAR_THEME.palette.primary.main,
          colorBackground: MOONSTAR_THEME.palette.background.paper,
        },
      }}
    >
      <LoggedInGuard>
        <AuthenticatedAdmin />
      </LoggedInGuard>
    </ClerkProvider>
  );
};

const AuthenticatedAdmin = () => {
  const { isLoading, isError, authProvider, dataProvider } =
    useReactAdminProviders();
  const i18nProvider = useMoonstarI18nProvider();

  if (isLoading) {
    return <AppLoading />;
  } else if (isError) {
    return <AppLoading isError />;
  }

  return (
    <Admin
      requireAuth
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      theme={MOONSTAR_THEME}
      layout={MoonstarLayout}
      queryClient={queryClient}
    />
  );
};
