import { FC } from 'react';
import { ExportButton, FilterButton, TopToolbar } from 'react-admin';
import { AddUsersButton } from './AddUsersButton';
import { BulkDisableUsersButton } from './BulkDisableUsersButton';
import { BulkUpdateUsersButton } from './BulkUpdateUsersButton';

export const UserToolbarActions: FC = () => {
  return (
    <TopToolbar>
      <FilterButton />
      <BulkDisableUsersButton />
      <BulkUpdateUsersButton />
      <AddUsersButton />
      <ExportButton />
    </TopToolbar>
  );
};
