import { FC } from 'react';
import { DeleteButton, SaveButton, Toolbar, ToolbarProps } from 'react-admin';

export type MoonstarEditFormToolbarProps = ToolbarProps & {
  hideDelete?: boolean;
  onDeleteRedirect?: () => string;
};

export const MoonstarEditFormToolbar: FC<MoonstarEditFormToolbarProps> = (
  props
) => {
  return (
    <Toolbar {...props}>
      <div className="RaToolbar-defaultToolbar">
        <SaveButton />
        {!props.hideDelete && (
          <DeleteButton
            mutationMode="pessimistic"
            redirect={props.onDeleteRedirect}
          />
        )}
      </div>
    </Toolbar>
  );
};
