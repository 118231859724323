import {
  CreateButton,
  EmptyClasses,
  useGetResourceLabel,
  useResourceContext,
  useResourceDefinition,
  useTranslate,
} from 'react-admin';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Inbox from '@mui/icons-material/Inbox';

// Forked from:
// https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/list/Empty.tsx
// to enable custom `to` endpoint on the `CreateButton`

export type MoonstarListEmptyProps = {
  hasCreate?: boolean;
  /**
   * The link to navigate to when creating a new item. Requires {@see hasCreate} to be set to `true` to render
   */
  createTo: string;
};

/**
 * Renders an "Empty" placeholder. Designed for use with nested resource links which have URLs that don't match the default react-admin pattern. Resource patterns like {baseUrl}/{resource}/create shouldn't need this helper. Must be wrapped in another element if rendering in a `List` to avoid props getting overwritten by React Admin
 * @example
 * ```tsx
 * <MoonstarList
        empty={
          <>
            <MoonstarListEmpty createTo="create" hasCreate />
          </>
        }
      >
      // ...
    </MoonstarList>
 * ```
 */
export const MoonstarListEmpty = (props: MoonstarListEmptyProps) => {
  const { hasCreate } = useResourceDefinition(props);
  const resource = useResourceContext();

  const translate = useTranslate();

  const getResourceLabel = useGetResourceLabel();
  const resourceName = translate(`resources.${resource}.forcedCaseName`, {
    count: 0,
    _: getResourceLabel(resource as string, 0),
  });

  const emptyMessage = translate('ra.page.empty', { name: resourceName });
  const inviteMessage = translate('ra.page.invite');

  return (
    <Root>
      <div className={EmptyClasses.message}>
        <Inbox className={EmptyClasses.icon} />
        <Typography variant="h4" paragraph>
          {translate(`resources.${resource}.empty`, {
            _: emptyMessage,
          })}
        </Typography>
        {hasCreate && (
          <Typography variant="body1">
            {translate(`resources.${resource}.invite`, {
              _: inviteMessage,
            })}
          </Typography>
        )}
      </div>
      {hasCreate && (
        <div className={EmptyClasses.toolbar}>
          <CreateButton to={props.createTo} variant="contained" />
        </div>
      )}
    </Root>
  );
};

const PREFIX = 'RaEmpty';

const Root = styled('span', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  flex: 1,
  [`& .${EmptyClasses.message}`]: {
    textAlign: 'center',
    opacity: theme.palette.mode === 'light' ? 0.5 : 0.8,
    margin: '0 1em',
    color:
      theme.palette.mode === 'light' ? 'inherit' : theme.palette.text.primary,
  },

  [`& .${EmptyClasses.icon}`]: {
    width: '9em',
    height: '9em',
  },

  [`& .${EmptyClasses.toolbar}`]: {
    textAlign: 'center',
    marginTop: '2em',
  },
}));
