import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useNotify, useTranslate } from 'react-admin';
import { useCallback } from 'react';
import { useGetCurrentUser } from '../../../hooks/useGetCurrentUser';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';

type CreateChannelVariables = {
  userIdsToInvite: string[];
  onCancel: () => void;
};

export const useInviteChannelMembers = (
  options?: UseMutationOptions<
    GroupChannel | undefined,
    Error,
    CreateChannelVariables
  >
) => {
  const notify = useNotify();
  const translate = useTranslate();
  const { channel, overrideInviteUser } = useChannelSettingsContext();
  const { data: currentUser } = useGetCurrentUser();

  const createInviteFailedNotify = useCallback(
    () => notify(translate('chat.groupInviteError'), { type: 'error' }),
    [notify, translate]
  );

  return useMutation<GroupChannel | undefined, Error, CreateChannelVariables>({
    mutationKey: ['create-channel'],
    mutationFn: async ({
      userIdsToInvite,
      onCancel,
    }: CreateChannelVariables) => {
      if (!channel || !currentUser) {
        createInviteFailedNotify();
        return;
      }

      if (typeof overrideInviteUser === 'function') {
        overrideInviteUser({
          users: userIdsToInvite,
          onClose: onCancel,
          channel,
        });
        return;
      } else {
        return await channel.inviteWithUserIds(userIdsToInvite);
      }
    },
    onError: createInviteFailedNotify,
    ...options,
  });
};
