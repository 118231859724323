import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useNotify, useTranslate } from 'react-admin';

type UnmuteUserParams = {
  id: string;
  channel: GroupChannel;
};

const unmuteUser = async ({ id, channel }: UnmuteUserParams) => {
  return await channel.unmuteUserWithUserId(id);
};

export const useUnmuteUser = (
  options?: UseMutationOptions<unknown, Error, UnmuteUserParams>
) => {
  const notify = useNotify();
  const translate = useTranslate();

  return useMutation({
    mutationKey: ['unmute-user'],
    mutationFn: unmuteUser,
    ...options,
    onError: () => {
      notify(translate('chat.unmuteUserError'), {
        autoHideDuration: 5000,
      });
    },
  });
};
