import React from 'react';
import { Grid } from '@mui/material';
import { Widget } from '../Widget';
import { useUsersWidgetsData } from '../../../../api/hooks/useUsersWidgetsData';

export const UsersWidgets: React.FC = () => {
  const { data } = useUsersWidgetsData();

  const { onboarded, activated, engaged } = data ?? {};

  const activationRate =
    ((activated?.total ?? 0) / (onboarded?.total ?? 0)) * 100;
  const activeThisMonthRate =
    ((engaged?.lastThirtyDays ?? 0) / (activated?.total ?? 0)) * 100;

  const diff =
    (engaged?.lastSevedDays ?? 0) - (engaged?.previousSevenDays ?? 0);
  const formattedDiff = `${diff >= 0 ? '+' : ''}${diff}`;

  if (data)
    return (
      <Grid
        container
        spacing={2}
        sx={{
          marginBottom: 2,
          marginTop: 0,
          justifyContent: 'space-between',
        }}
      >
        <Grid item xs={12} md={2.4}>
          <Widget
            title="Onboarded Users"
            textPrimary={onboarded?.total ?? 0}
            textSecondary={`+${onboarded?.lastSevedDays} in last 7 days`}
            description="Their details have been imported into Moonstar by an Admin"
            color="primary"
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Widget
            title="Activated Users"
            textPrimary={activated?.total ?? 0}
            textSecondary={`+${activated?.lastSevedDays} in last 7 days`}
            description="They have logged into the Admin Portal or installed and logged into the Moonstar App"
            color="primary"
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Widget
            title="Engaged Users"
            textPrimary={engaged?.lastSevedDays ?? 0}
            textSecondary={`${formattedDiff} compared to 7 days ago`}
            description="They have used the Admin Portal or Moonstar App within the previous 7 days"
            color="primary"
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Widget
            title="Activation Rate"
            textPrimary={`${activationRate.toFixed(1)}%`}
            textSecondary={`(${activated?.total}/${onboarded?.total})`}
            description="Percentage of the activated users out of onboarded"
            color="primary"
          />
        </Grid>
        <Grid item xs={12} md={2.4}>
          <Widget
            title="Engaged this month"
            textPrimary={`${activeThisMonthRate.toFixed(1)}%`}
            textSecondary={`(${engaged?.lastThirtyDays}/${activated?.total})`}
            description={
              'Percentage of users engaged in last 30 days out of activated'
            }
            color="primary"
          />
        </Grid>
      </Grid>
    );
};
