import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material';
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Legend,
  Line,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import { format } from 'date-fns';

interface UsersChartProps {
  title: string;
  chartData: any[];
  dataKey: string;
  lineName: string;
  includePercentage?: boolean;
  percentageLineName?: string;
}

export const UsersChart: React.FC<UsersChartProps> = ({
  title,
  chartData,
  dataKey,
  lineName,
  includePercentage = false,
  percentageLineName,
}) => {
  const {
    moonstar: { charts },
  } = useTheme();

  return (
    <Card sx={{ boxShadow: 3, height: 400 }}>
      <CardHeader
        title={
          <Typography
            variant="h6"
            sx={{ fontWeight: 'bold', fontSize: '0.8rem' }}
          >
            {title}
          </Typography>
        }
      />
      <CardContent sx={{ paddingBottom: '0px' }}>
        <ResponsiveContainer width="100%" height={250}>
          <LineChart
            data={chartData}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 30,
            }}
          >
            <CartesianGrid horizontal={true} vertical={false} />
            <XAxis
              dataKey="date"
              tickFormatter={(date) => format(new Date(date), 'EEE dd/MM')}
              tick={{ fontSize: 12 }}
              angle={-45}
              textAnchor="end"
            />
            <YAxis
              yAxisId="left"
              tick={{ fontSize: 12 }}
              stroke={charts.palette.color1}
              allowDecimals={false}
              tickCount={5}
            />
            {includePercentage && (
              <YAxis
                yAxisId="right"
                orientation="right"
                tick={{ fontSize: 12 }}
                domain={[0, 100]}
                stroke={charts.palette.color3}
              />
            )}
            <Tooltip />
            <Legend
              verticalAlign="bottom"
              align="center"
              iconType="circle"
              iconSize={7}
              height={1}
              wrapperStyle={{
                paddingTop: 0,
                marginBottom: -40,
              }}
            />
            <Line
              yAxisId="left"
              type="linear"
              dataKey={dataKey}
              name={lineName}
              stroke={charts.palette.color1}
              strokeWidth={2}
              dot={false}
            />
            {includePercentage && (
              <Line
                yAxisId="right"
                type="natural"
                dataKey="percentage"
                name={percentageLineName}
                stroke={charts.palette.color3}
                strokeWidth={2}
                dot={false}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
