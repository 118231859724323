import ChannelSettingsUI from '@sendbird/uikit-react/ChannelSettings/components/ChannelSettingsUI';
import { CustomModerationPanel } from './CustomModerationPanel';
import { CustomChannelProfile } from './CustomChannelProfile';

export const GroupCustomChannelSettingsUI = () => {
  return (
    <ChannelSettingsUI
      renderModerationPanel={() => <CustomModerationPanel />}
      renderChannelProfile={() => <CustomChannelProfile />}
    />
  );
};
