import ChannelAvatar from '@sendbird/uikit-react/ui/ChannelAvatar';
import EditDetailsModal from '@sendbird/uikit-react/ChannelSettings/components/EditDetailsModal';
import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';
import { useGetCurrentUser } from '../../../../hooks/useGetCurrentUser';
import { getChatChannelName } from '../../../../utils/getChatChannelName';
import { Role } from '@sendbird/chat';
import { Loading } from 'react-admin';

export const CustomChannelProfile = () => {
  const { channel } = useChannelSettingsContext();
  const { data: currentUser } = useGetCurrentUser();
  const [showModal, setShowModal] = useState(false);

  const handleEditClick = () => {
    setShowModal(true);
  };

  if (!channel || !currentUser) {
    return <Loading />;
  }

  const isUserChannelOperator = channel.myRole === Role.OPERATOR;

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {/* Only operators are allowed to edit channel profile */}
        {isUserChannelOperator && (
          <Button
            onClick={handleEditClick}
            variant="text"
            sx={{ marginLeft: 'auto', marginTop: 1, marginRight: 1 }}
          >
            Edit
          </Button>
        )}
        <Box
          sx={{
            alignSelf: 'center',
            ...(!isUserChannelOperator && { paddingTop: 1 }),
          }}
        >
          <ChannelAvatar
            channel={channel}
            userId={currentUser.id.toString()}
            theme="light"
            width={80}
            height={80}
          />
        </Box>
        <Typography
          sx={{
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingBottom: 1,
            paddingLeft: 2,
            paddingRight: 2,
          }}
          variant="subtitle1"
        >
          {getChatChannelName(channel, currentUser)}
        </Typography>
      </Box>

      {showModal && (
        <EditDetailsModal
          onCancel={() => setShowModal(false)}
          onSubmit={() => setShowModal(false)}
        />
      )}
    </>
  );
};
