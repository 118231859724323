import {
  createContext,
  useContext,
  useMemo,
  FC,
  PropsWithChildren,
} from 'react';
import { SendbirdChatWith } from '@sendbird/chat';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import { sendBirdColorSet } from './colorSet';
import { useGetCurrentUser } from '../../../hooks/useGetCurrentUser';
import { useFetchSendBirdAppId } from '../../../api/hooks/useFetchSendBirdAppId';
import { Error as ErrorPage, Loading } from 'react-admin';
import { useInitializeSendbirdSDK } from '../../../api/hooks/sendbird/useInitializeSendbirdSDK';
import { useGoToChannel } from './useGoToChannel';
import UserProfile from './UserProfile';

type SendbirdContextValue = {
  sb: SendbirdChatWith<GroupChannelModule[]> | undefined;
};

const SendbirdContext = createContext<SendbirdContextValue | undefined>(
  undefined
);

export const SendbirdProviderWithSDK: FC<PropsWithChildren> = ({
  children,
}) => {
  const { data: user } = useGetCurrentUser();
  const { data: sendBirdApp } = useFetchSendBirdAppId();
  const {
    data: sendbirdInstance,
    isLoading,
    isError,
    error,
    refetch,
  } = useInitializeSendbirdSDK();

  const contextValue = useMemo(
    () => ({ sb: sendbirdInstance }),
    [sendbirdInstance]
  );

  const goToChannel = useGoToChannel();

  if (!sendBirdApp?.appId || !user?.id || isLoading) {
    return <Loading />;
  }

  if (isError) {
    return (
      <ErrorPage error={error} resetErrorBoundary={() => void refetch()} />
    );
  }

  return (
    <SendbirdContext.Provider value={contextValue}>
      <SendbirdProvider
        appId={sendBirdApp.appId}
        userId={user.id.toString()}
        colorSet={sendBirdColorSet}
        renderUserProfile={(props) => <UserProfile {...props} />}
        onStartDirectMessage={(channel) => goToChannel(channel.url)}
      >
        {children}
      </SendbirdProvider>
    </SendbirdContext.Provider>
  );
};

export const useSendbirdContext = (): SendbirdContextValue => {
  const context = useContext(SendbirdContext);
  if (!context) {
    throw new Error(
      'useSendbirdContext must be used within a SendbirdProviderWithSDK.'
    );
  }
  return context;
};
