import { FC, useCallback, useMemo } from 'react';
import { UserCsvRow, userSchema } from './types';
import { BulkOperation } from '../BulkOperation/BulkOperation';
import { z } from 'zod';
import { HttpError, useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../../../api/adminDataProvider';
import {
  GridRowValidator,
  GridRowValidationError,
  RowProcessor,
} from '../BulkOperation/types';
import { superRefineDuplicateProps } from '../BulkOperation/bulkZodUtils';

const exampleValues = [
  {
    internalId: 'example-id',
    firstName: 'John',
    lastName: 'Smith',
    phoneNumber: '+40123456789',
    email: 'example@gmail.com',
    startDate: new Date().toISOString().substring(0, '0001-01-01'.length),
    department: 'HR',
    jobTitle: 'Manager',
    region: 'UK',
    unit: 'London',
    languageCountryCode: 'en',
  },
] as const satisfies UserCsvRow[];

export const AddUsersButton: FC = () => {
  const dataProvider = useDataProvider<AdminDataProvider>();

  const csvSchema = useMemo(
    () =>
      z.array(userSchema).superRefine((allRows, ctx) => {
        superRefineDuplicateProps('phoneNumber', allRows, ctx);
        superRefineDuplicateProps(
          'internalId',
          allRows.filter((r) => !!r.internalId),
          ctx
        );
      }),
    []
  );

  const userValidator = useCallback<
    GridRowValidator<z.infer<typeof userSchema>>
  >(
    async ({ data: user }) => {
      const phoneRes = await dataProvider.getUserByPhoneNumber(
        user.phoneNumber
      );

      const errors: GridRowValidationError<z.infer<typeof userSchema>>[] = [];
      if (phoneRes) {
        errors.push({
          message: `This phone number is already taken by user: ${phoneRes.firstName} ${phoneRes.lastName}`,
          property: 'phoneNumber',
        });
      }

      if (user.internalId) {
        const internalIdRes = await dataProvider.getUserByInternalId(
          user.internalId
        );

        if (internalIdRes) {
          errors.push({
            message: `This internal identifier is already taken by user: ${internalIdRes.firstName} ${internalIdRes.lastName}`,
            property: 'internalId',
          });
        }
      }

      return (oldData) => ({
        ...oldData,
        status: errors.length > 0 ? 'error' : 'success',
        data: user,
        asyncErrors: errors,
      });
    },
    [dataProvider]
  );

  const userProcessor = useCallback<RowProcessor<z.infer<typeof userSchema>>>(
    async ({ data: user }) => {
      // await new Promise((res) => setTimeout(res, 10_000));
      // return (oldData) => ({
      //   ...oldData,
      //   status: 'success',
      // });

      const res = await dataProvider.postUser(user).catch((err: unknown) => {
        if (err instanceof HttpError) {
          // NOTE: HttpError won't return the response body, as it isn't JSON, so it ignores it
          // https://github.com/marmelab/react-admin/blob/0a7a6e7f612990dca093fbbf4cfa531ead4583a2/packages/ra-core/src/dataProvider/fetch.ts#L67
          return err;
        }

        throw err;
      });

      if (res.status === 200) {
        return (oldData) => ({
          ...oldData,
          data: user,
          status: 'success',
          errors: [],
        });
      } else {
        return (oldData) => ({
          ...oldData,
          data: user,
          status: 'error',
          errors: [
            res.body ??
              'Unexpected error. Please reach out to Moonstar Support.',
          ],
        });
      }
    },
    [dataProvider]
  );

  return (
    <BulkOperation
      label="Add users"
      resource="users"
      schema={csvSchema}
      validateRow={userValidator}
      exampleValues={exampleValues}
      processRow={userProcessor}
      // due to clerk's 20reqs/10s limit
      validationRateLimitDelay={500}
      processingRateLimitDelay={10_000}
    />
  );
};
