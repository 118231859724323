import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { useNotify, useTranslate } from 'react-admin';

type BanUserParams = {
  id: string;
  channel: GroupChannel;
};

const banUser = async ({ id, channel }: BanUserParams) => {
  return await channel.banUserWithUserId(id);
};

export const useBanUser = (
  options?: UseMutationOptions<unknown, Error, BanUserParams>
) => {
  const notify = useNotify();
  const translate = useTranslate();

  return useMutation({
    mutationKey: ['ban-user'],
    mutationFn: banUser,
    ...options,
    onError: () => {
      notify(translate('chat.removeUserError'), {
        autoHideDuration: 5000,
      });
    },
  });
};
