import { useMemo } from 'react';
import { CsvRecord, GridRow } from './types';

interface BulkOperationDialogGridFooterProps<T extends CsvRecord = CsvRecord> {
  gridData: GridRow<T>[];
}

export function BulkOperationDialogGridFooter<T extends CsvRecord>({
  gridData,
}: BulkOperationDialogGridFooterProps<T>) {
  const invalidRecords = useMemo(
    () => gridData.filter((d) => d.status === 'error'),
    [gridData]
  );

  const validationProgress = useMemo(() => {
    let successCount = 0,
      errorCount = 0,
      processingCount = 0;

    for (const row of gridData) {
      switch (row.status) {
        case 'success':
          successCount++;
          break;
        case 'error':
          errorCount++;
          break;
        default:
          processingCount++;
      }
    }

    return {
      total: gridData.length,
      successCount,
      errorCount,
      processingCount,
    };
  }, [gridData]);

  return validationProgress.processingCount > 0 ? (
    <p>
      <b>Validating...</b>{' '}
      <span>
        ({validationProgress.successCount + validationProgress.errorCount}/
        {validationProgress.total})
      </span>
    </p>
  ) : (
    <p>
      <span>
        <b>Total: </b>
        <span>{validationProgress.total}</span>
      </span>
      {validationProgress.errorCount > 0 ? (
        <span>
          , <b>Invalid: </b>
          <span>{validationProgress.errorCount}</span>{' '}
          <span>
            (row{' '}
            {invalidRecords
              .slice(0, 5)
              .map((r) => r.id + 1)
              .join(', ')}
            {invalidRecords.length > 5 ? '...' : ''})
          </span>
        </span>
      ) : null}
    </p>
  );
}
