import { useState } from 'react';
import { Button } from 'react-admin';
import GetAppIcon from '@mui/icons-material/GetApp';
import { BulkOperationDialog } from './BulkOperationDialog';
import { CsvRecord, GridRowValidator, RowProcessor } from './types';
import { ZodCsvSchema } from './bulkZodUtils';

export interface BulkOperationProps<T extends CsvRecord> {
  label: string;
  resource: string;
  validateRow?: GridRowValidator<T>;
  schema: ZodCsvSchema<T>;
  /**
   * Example values to be included in the exported CSV.
   */
  exampleValues?: T[];
  validationRateLimitDelay?: number;
  processRow: RowProcessor<T>;
  processingRateLimitDelay?: number;
}

export function BulkOperation<T extends CsvRecord>({
  label,
  resource,
  validateRow,
  schema,
  exampleValues,
  validationRateLimitDelay = 0,
  processRow,
  processingRateLimitDelay = 0,
}: BulkOperationProps<T>) {
  // Ensure that the Dialog's state gets reset whenever the Dialog is closed
  const [dialogKey, setDialogKey] = useState(() => crypto.randomUUID());

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <Button
        label={label}
        onClick={() => setDialogOpen(true)}
        startIcon={
          <GetAppIcon style={{ transform: 'rotate(180deg)', fontSize: '20' }} />
        }
      />
      <BulkOperationDialog
        key={dialogKey}
        label={label}
        resource={resource}
        open={dialogOpen}
        validationRateLimitDelay={validationRateLimitDelay}
        onClose={() => {
          setDialogOpen(false);

          // Reset the dialog's internal state
          setDialogKey(crypto.randomUUID());
        }}
        validateRow={validateRow}
        schema={schema}
        exampleValues={exampleValues}
        processRow={processRow}
        processingRateLimitDelay={processingRateLimitDelay}
      />
    </>
  );
}
