import { HttpClient } from '../httpClient';
import { Params, Response } from '../hooks/useFeedWidgetsData';

export const getFeedWidgetsData =
  (httpClient: HttpClient) => async (params: Params) => {
    const queryString = new URLSearchParams(
      params as unknown as Record<string, string>
    ).toString();

    const response = await httpClient.api(
      `/admin/posts/widgets?${queryString}`,
      {
        method: 'GET',
      }
    );

    return response.json as Response;
  };
