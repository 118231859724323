import { ReactNode, useCallback } from 'react';
import { UserDto } from '../../../api/customEndpoints/fetchAllUsers';
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';

type UserCardProps = Omit<UserDto, 'id' | 'region'> & {
  onClick?: () => void;
  AvatarOverlay?: ReactNode;
  right?: ReactNode;
  disabled?: boolean;
  disableRipple?: boolean;
};

export const UserCard = ({
  internalId,
  displayName,
  pictureUrl,
  jobTitle,
  department,
  units,
  onClick,
  right,
  AvatarOverlay,
  disabled,
  disableRipple,
}: UserCardProps) => {
  const renderLeft = useCallback(() => {
    return (
      <>
        <Avatar sizes="54px" src={pictureUrl} />
        {AvatarOverlay}
      </>
    );
  }, [AvatarOverlay, pictureUrl]);

  return (
    <Card square variant="outlined" elevation={0}>
      <CardActionArea
        onClick={onClick}
        disabled={disabled}
        disableRipple={disableRipple}
        disableTouchRipple={disableRipple}
      >
        <CardContent
          sx={{ display: 'flex', alignItems: 'center', gap: 1, padding: 1 }}
        >
          {renderLeft()}
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">{displayName}</Typography>
            <Typography variant="caption">
              {[internalId, jobTitle, department, units[0]]
                .filter(Boolean)
                .join(', ')}
            </Typography>
          </Box>
          {right}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
