import { FC, useCallback, useMemo } from 'react';
import { HttpError, useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../../../api/adminDataProvider';
import { userSchema } from './types';
import 'react-data-grid/lib/styles.css';
import './ImportUsersButton.css';
import { parse } from 'date-fns';
import { BulkOperation } from '../BulkOperation/BulkOperation';
import { GridRowValidator, RowProcessor } from '../BulkOperation/types';
import { z } from 'zod';
import { superRefineDuplicateProps } from '../BulkOperation/bulkZodUtils';

export const BulkUpdateUsersButton: FC = () => {
  const userUpdateSchema = useMemo(
    () =>
      z
        .object({
          id: z.string().optional(),
          ...userSchema.shape,
        })
        .superRefine((data, ctx) => {
          if (!(data.id || data.internalId)) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Either 'id' or 'internalId' must be provided.",
              path: ['id'],
            });
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Either 'id' or 'internalId' must be provided.",
              path: ['internalId'],
            });
          }
        }),
    []
  );

  const csvSchema = useMemo(
    () =>
      z
        .array(userUpdateSchema)
        .superRefine((data, ctx) =>
          superRefineDuplicateProps(
            'id',
            data.filter((d) => !!d.id),
            ctx
          )
        )
        .superRefine((data, ctx) =>
          superRefineDuplicateProps(
            'internalId',
            data.filter((d) => !!d.internalId),
            ctx
          )
        ),
    [userUpdateSchema]
  );

  const dataProvider = useDataProvider<AdminDataProvider>();

  const toUtcIsoString = useCallback(
    (dateString: string, format = 'dd/MM/yyyy'): string =>
      new Date(
        Date.UTC(
          parse(dateString, format, new Date()).getFullYear(),
          parse(dateString, format, new Date()).getMonth(),
          parse(dateString, format, new Date()).getDate()
        )
      ).toISOString(),
    []
  );

  const validateRow = useCallback<
    GridRowValidator<z.infer<typeof userUpdateSchema>>
  >(
    async ({ data: user }) => {
      const res = await dataProvider.getUser({
        id: user.id,
        internalId: user.internalId,
      });

      if (!res) {
        if (user.internalId) {
          return (oldData) => ({
            ...oldData,
            status: 'error',
            data: user,
            asyncErrors: [
              {
                message: "User with this internal id doesn't exist.",
                property: 'internalId',
              },
            ],
          });
        } else {
          return (oldData) => ({
            ...oldData,
            status: 'error',
            data: user,
            asyncErrors: [
              {
                message: "User with this id doesn't exist.",
                property: 'id',
              },
            ],
          });
        }
      } else {
        return (oldData) => ({
          ...oldData,
          status: 'success',
          asyncErrors: [],
          data: {
            ...user,
            id: user.internalId ? res.id.toString() : user.id,
          },
        });
      }
    },
    [dataProvider]
  );

  const processRow = useCallback<
    RowProcessor<z.infer<typeof userUpdateSchema>>
  >(
    async (row) => {
      const response = await dataProvider
        .update('users', {
          id: row.data.id,
          data: {
            ...row.data,
            startDate: row.data.startDate
              ? toUtcIsoString(row.data.startDate)
              : null,
          },
          previousData: undefined,
        })
        .catch((err: unknown) => {
          if (err instanceof HttpError) {
            // NOTE: HttpError won't return the response body, as it isn't JSON, so it ignores it
            // https://github.com/marmelab/react-admin/blob/0a7a6e7f612990dca093fbbf4cfa531ead4583a2/packages/ra-core/src/dataProvider/fetch.ts#L67
            return err;
          }

          throw err;
        });

      if (response instanceof HttpError) {
        return (oldData) => ({
          ...oldData,
          status: 'error',
          data: row.data,
          errors: [
            response.body ??
              'Unexpected error. Please reach out to Moonstar Support.',
          ],
        });
      } else {
        return (oldData) => ({
          ...oldData,
          status: 'success',
          data: row.data,
        });
      }
    },
    [dataProvider, toUtcIsoString]
  );

  return (
    <BulkOperation
      label="moonstar.bulk_update.label"
      resource="users"
      schema={csvSchema}
      validateRow={validateRow}
      processRow={processRow}
      // due to clerk's 20reqs/10s limit
      validationRateLimitDelay={500}
      processingRateLimitDelay={500}
    />
  );
};
