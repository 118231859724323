import { HttpClient } from '../httpClient';
import { Response } from '../hooks/useUsersChartData';

export const getUsersChartData =
  (httpClient: HttpClient) => async (data: any) => {
    const response = await httpClient.api('/admin/users/chart', {
      method: 'POST',
      body: JSON.stringify(data),
    });

    return response.json as Response;
  };
