import { HttpClient } from '../httpClient';

export type NudgeReportEntry = {
  nudgeId: number;
  categories: string;
  nudgeTitle: string;
  nudgeCreatedOn: string;
  nudgeLastEditedOn: string;
  employeeId: number;
  employeeInternalId: string | null;
  employeeName: string;
  jobTitle: string;
  department: string;
  region: string;
  units: string;
  totalSlides: number;
  firstAccessedOn: string | null;
  lastAccessedOn: string | null;
  accessedSlides: number;
  completed: string;
  rating: number | null;
  isInterestingRating: boolean | null;
};

export const getNudgesReport =
  (httpClient: HttpClient) => async (nudgeId: string | number) => {
    const res = await httpClient.api(`/admin/nudges/${nudgeId}/report`, {
      method: 'GET',
    });

    return res.json as { data: NudgeReportEntry[] };
  };
