import {
  ChipField,
  Datagrid,
  DateField,
  ImageField,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { ResourcesValue } from '../AdminResources';
import { saveCsv } from '../BulkOperation/saveCsv';
import { MoonstarList } from '../Moonstar/MoonstarList';
import { BulkUserDisableAction } from './BulkUserDisableAction';
import { UserFilters } from './UserFilters';
import { UserToolbarActions } from './UserToolbarActions';
import { profilePictureStyles } from './profilePictureStyles';

interface UserListEntry {
  id: number;
  internalId?: string;
  email?: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
  pictureUrl?: string;
  startDate?: string;
  departmentName: string;
  jobTitleName: string;
  regionName: string;
  unitIds: number[];
  activatedOn?: string;
  disabledFrom?: string;
  roleId?: string;
}

const exporter = (users: UserListEntry[]) => {
  const columns = [
    // Match as close as possible to the CSV import template
    'id',
    'internalId',
    'firstName',
    'lastName',
    'phoneNumber',
    'email',
    'startDate',
    'jobTitle',
    'department',
    'region',
    'unit',
    'activatedOn',
    'disabledFrom',
    'roleId',
  ] as const;

  saveCsv({
    data: users.map((u) => ({
      id: u.id,
      internalId: u.internalId,
      firstName: u.firstName,
      lastName: u.lastName,
      phoneNumber: u.phoneNumber!,
      email: u.email,
      department: u.departmentName,
      jobTitle: u.jobTitleName,
      region: u.regionName,
      unit: u.unitIds.join(', '),
      startDate: u.startDate,
      activatedOn: u.activatedOn,
      disabledFrom: u.disabledFrom,
      roleId: u.roleId,
    })),
    basename: ResourcesValue.USERS,
    columns: [...columns],
  });
};

export const UserList = () => {
  return (
    <MoonstarList<UserListEntry>
      actions={<UserToolbarActions />}
      filters={UserFilters}
      filterDefaultValues={{ userIsDisabled: false }}
      exporter={exporter}
    >
      <Datagrid bulkActionButtons={<BulkUserDisableAction />} rowClick="edit">
        <ImageField<UserListEntry>
          source="pictureUrl"
          sx={profilePictureStyles}
          sortable={false}
        />
        <TextField<UserListEntry> source="internalId" label="Internal ID" />
        <TextField<UserListEntry> source="firstName" />
        <TextField<UserListEntry> source="lastName" />
        <TextField<UserListEntry> source="phoneNumber" />
        <TextField<UserListEntry> source="email" />
        <TextField<UserListEntry> source="jobTitleName" />
        <TextField<UserListEntry> source="departmentName" />
        <TextField<UserListEntry> source="regionName" />
        <ReferenceArrayField<UserListEntry>
          source="unitIds"
          reference="units"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField<UserListEntry> source="activatedOn" />
        <DateField<UserListEntry> source="disabledFrom" />
        <TextField<UserListEntry> source="roleId" />
      </Datagrid>
    </MoonstarList>
  );
};
