import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { ChannelModalCard } from './ChannelModalCard';
import { useCreateChannelContext } from '@sendbird/uikit-react/CreateChannel/context';
import { CHANNEL_TYPE } from './types';
import { useState } from 'react';
import { CreateGroupsModal } from './CreateGroupsModal';
import { useTranslate } from 'react-admin';
import { useHasRole } from '../../../../auth/userRoleContext';

export const SelectCreateGroupsModal = (props: DialogProps) => {
  const translate = useTranslate();
  const isAdmin = useHasRole('Admin');
  const { setType } = useCreateChannelContext();
  const [openCreateGroups, setOpenCreateGroups] = useState(false);

  const handleClose = () => {
    props.onClose?.({}, 'escapeKeyDown');
  };

  const handleCreateGroup = () => {
    setType(CHANNEL_TYPE.GROUP as any);
    handleClose();
    setOpenCreateGroups(true);
  };

  const handleCreateSuperGroup = () => {
    setType(CHANNEL_TYPE.SUPERGROUP as any);
    handleClose();
    setOpenCreateGroups(true);
  };

  return (
    <>
      <Dialog {...props} fullWidth>
        <>
          <DialogTitle>{translate('chat.createNewGroup')}</DialogTitle>
          <DialogContent style={{ gap: 12 }}>
            <ChannelModalCard
              onClick={handleCreateGroup}
              cardText="Group"
              type="GROUP"
            />
            {isAdmin && (
              <ChannelModalCard
                onClick={handleCreateSuperGroup}
                cardText="Supergroup"
                type="SUPERGROUP"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginBottom: 8, marginRight: 8 }}
              onClick={handleClose}
            >
              {translate('chat.close')}
            </Button>
          </DialogActions>
        </>
      </Dialog>
      <CreateGroupsModal
        open={openCreateGroups}
        onClose={() => setOpenCreateGroups(false)}
      />
    </>
  );
};
