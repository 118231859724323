import { useQuery } from '@tanstack/react-query';
import { useGetCurrentUser } from '../../../hooks/useGetCurrentUser';
import { useFetchSendBirdAppId } from '../useFetchSendBirdAppId';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import SendbirdChat from '@sendbird/chat';

export const useInitializeSendbirdSDK = () => {
  const { data: user } = useGetCurrentUser();
  const { data: sendBirdApp } = useFetchSendBirdAppId();

  return useQuery({
    queryKey: ['initialize-sendbird-sdk'],
    queryFn: async () => {
      const instance = SendbirdChat.init({
        appId: sendBirdApp!.appId,
        modules: [new GroupChannelModule()],
      });

      await instance.connect(user!.id.toString());

      return instance;
    },
    enabled: !!user?.id && !!sendBirdApp?.appId,
  });
};
