import { Paper, styled } from '@mui/material';
import { useGetList } from 'react-admin';
import { MOONSTAR_THEME } from '../../../../theme';
import { usePostCreateContext } from '../PostCreateProvider';
import { BasicPostPreview, BasicPostPreviewData } from './BasicPostPreview';
import { PollPostPreview, PollPostPreviewData } from './PollPostPreview';
import {
  RecognitionPostPreview,
  RecognitionPostPreviewData,
} from './RecognitionPostPreview';

type PostPreviewProps = {
  postType: number | undefined;
};

// TODO: Possibly move this object to a theme?
export const postPreviewColors = {
  cardBackground: '#F9F8F7',
  dateFontColor: '#322f3766',
  recognitionValue: '#12AD8F',
  secondaryText: '#3E4B63',
  postPollParticipants: '#567585',
  pollOption: '#226C79',
  pollOptionBorderNoVote: '#28808F80',
  pollOptionBackgroundNoVote: '#F4F6F9',
  pollOptionBackground: '#EDF1F7',
  recognitionContentContainer: '#ebf5ff',
  primary: MOONSTAR_THEME.palette.primary.main,
};

export const PostPreview = (props: PostPreviewProps) => {
  const { selectedTopic, formData } = usePostCreateContext();
  const { data: topicsData, isSuccess: isSuccessTopics } = useGetList('topics');

  const postTopicDisplayName =
    isSuccessTopics &&
    topicsData.find((t) => t.id === selectedTopic)?.displayName;

  const getPreviewFromTopic = () => {
    // previewData transforms are result of formData being array of values from forms (BasicForm, PollForm, RecognitionForm)
    switch (props.postType) {
      case 3: {
        const previewData: PollPostPreviewData = {
          topicId: formData[0],
          title: formData[1],
          options: formData[2],
          attachments: formData[3],
        };

        return (
          <PollPostPreview
            topicDisplayName={postTopicDisplayName}
            previewData={previewData}
          />
        );
      }
      case 2: {
        const previewData: RecognitionPostPreviewData = {
          topicId: formData[0],
          recognizedUserId: formData[1],
          badgeId: formData[2],
          content: formData[3],
          attachments: formData[4],
        };

        return (
          <RecognitionPostPreview
            topicDisplayName={postTopicDisplayName}
            previewData={previewData}
          />
        );
      }
      // Anything else needs to be a BasicFormPreview
      default: {
        const previewData: BasicPostPreviewData = {
          topicId: formData[0],
          content: formData[1],
          attachments: formData[2],
        };

        return (
          <BasicPostPreview
            topicDisplayName={postTopicDisplayName}
            previewData={previewData}
          />
        );
      }
    }
  };

  return (
    <PostPreviewAreaContainer>
      <PostPreviewPhoneContainer square>
        <FeedPreviewHeader>
          <img
            src="https://files.dev.aws.joinmoonstar.app/public/dev/moonstar-grow-logo.svg"
            alt="logo"
            style={{ maxWidth: 140, maxHeight: 70 }}
          />
        </FeedPreviewHeader>
        <FeedPreviewCardContainer>
          {getPreviewFromTopic()}
        </FeedPreviewCardContainer>
      </PostPreviewPhoneContainer>
    </PostPreviewAreaContainer>
  );
};

const PostPreviewAreaContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  height: '100%',
  marginTop: theme.spacing(1.7),
  gap: theme.spacing(5),
}));

const PostPreviewPhoneContainer = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(5),
  overflow: 'scroll',
  scrollbarWidth: 'none',
  position: 'relative',
  width: 360,
  height: 760,
  backgroundColor: '#E9E5E0',
  borderRadius: 40,
  boxShadow:
    '0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111',
  '&:before, &:after': {
    content: "''",
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  // home button indicator
  '&:after': {
    bottom: 7,
    width: 140,
    height: 4,
    backgroundColor: '#f2f2f2',
    borderRadius: 10,
  },

  // frontal camera/speaker frame
  '&:before': {
    top: 0,
    width: '56%',
    height: 30,
    backgroundColor: '#1f1f1f',
    borderRadius: '0px 0px 40px 40px',
  },
}));

const FeedPreviewHeader = styled('div')(() => ({
  backgroundColor: postPreviewColors.cardBackground,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  paddingTop: 45,
  paddingBottom: 15,
  borderTopLeftRadius: 40,
  borderTopRightRadius: 40,
}));

const FeedPreviewCardContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  marginTop: theme.spacing(1),
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));
