import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../adminDataProvider';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export interface Params {
  after: string;
  before: string;
}

export interface Entry {
  id: number;
  createdOn: string;
  createdById: number;
}

export interface Response {
  Posts: Entry[];
  Reactions: Entry[];
  Comments: Entry[];
}

export const usePostsChartData = (
  params: Params,
  options?: UseQueryOptions<Response, Error, Response>
) => {
  const dataProvider = useDataProvider<AdminDataProvider>();

  return useQuery<Response>({
    queryKey: ['getPostsChartData', params],
    queryFn: () => dataProvider.getPostsChartData(params),
    staleTime: 1000 * 60,
    ...options,
  });
};
