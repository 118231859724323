import { Entry, Params } from '../hooks/usePulseQuestionsChartData';
import { HttpClient } from '../httpClient';

export const getPulseQuestionsChartData =
  (httpClient: HttpClient) => async (params: Params) => {
    const queryString = new URLSearchParams(
      params as unknown as Record<string, string>
    ).toString();

    const response = await httpClient.api(`/admin/pulse/chart?${queryString}`, {
      method: 'GET',
    });

    return response.json as Entry[];
  };
